import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AccountSettingsService,
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-teammates-modal',
  templateUrl: './add-teammates-modal.component.html',
})
export class AddTeammatesModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output('updateTeamsList') updateList = new EventEmitter();

  rolesList: Array<Select2OptionData> = [];

  constructor(
    private accountSettingsService: AccountSettingsService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRolesList();
  }

  teamForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      ValidationService.required,
      Validators.pattern(
        /^([A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))$/
      ),
    ]),
    role: new UntypedFormControl('', [ValidationService.required]),
  });

  getRolesList(): void {
    const vendorID = this.storageService.getCookie('userID');
    this.accountSettingsService
      .getRolesList(vendorID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            const roles = res?.data;
            roles.filter((item: any) => {
              this.rolesList.push({
                id: item._id,
                text: item.role_name,
              });
            });
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  setTeammate(): void {
    if (this.teamForm.invalid) {
      this.teamForm.markAllAsTouched();
      return;
    } else {
      const payload = {
        email: this.teamForm.value.email,
        role: this.teamForm.value.role,
        seller: this.storageService.getCookie('userID'),
      };
      this.accountSettingsService
        .setTeammate(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.updateList.emit();
              this.closeModal();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  get f() {
    return this.teamForm.controls;
  }

  closeModal(): void {
    this.closeMdl.nativeElement.click();
    this.teamForm.reset();
  }
}
