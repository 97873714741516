<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{
          defaultPackage
            ? selectedPackageData?.title
            : selectedPackageData?.name
        }}
      </h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <form class="edit-form-row package-form-row" [formGroup]="packageData">
        <div class="form-group">
          <div class="left-title">
            <label> Package Title<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input
              appAlphaNumeric
              [allowSpecialCharacters]="'-'"
              type="text"
              class="form-control"
              placeholder="Title"
              maxlength="40"
              formControlName="title"
              maxlength="100"
            />
            <div
              *ngIf="f['title'].errors && f['title'].errors['required']"
              class="required-note text-danger"
            >
              Title is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="left-title">
            <label>Regular Price</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input
                type="text"
                class="form-control"
                gfdNumbersOnly
                placeholder="Regular Price"
                formControlName="regular_price"
                (ngModelChange)="checkValidPrice()"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Sale Price<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input
                type="text"
                class="form-control"
                gfdNumbersOnly
                [maxAllowedValue]="
                  packageData.controls['regular_price'].value > 0
                    ? packageData.controls['regular_price'].value
                    : undefined
                "
                placeholder="Sale Price"
                formControlName="sale_price"
                (ngModelChange)="checkValidPrice()"
              />
            </div>
            <div
              *ngIf="f['sale_price'].invalid && f['sale_price'].touched"
              class="cross-validation-error-message"
            >
              <div
                *ngIf="
                  f['sale_price'].errors && f['sale_price'].errors['required']
                "
                class="required-note text-danger"
              >
                Sale price is required.
              </div>
              <div
                *ngIf="
                  f['sale_price'].errors &&
                  f['sale_price'].errors['salePriceInvalid'] &&
                  isSubmitted
                "
                class="required-note text-danger"
              >
                Sale price must be lower then regular price.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Tax</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="taxPercentage"
                [ngModelOptions]="{ standalone: true }"
                [readOnly]="true"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Total Price</label>
          </div>
          <div class="right-field">
            <div class="currency-input-group">
              <span class="price-currency">AED</span>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="totalAmount"
                [ngModelOptions]="{ standalone: true }"
                [readOnly]="true"
                [value]="calculateTotalAmount()"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <label>Description</label>
          </div>
          <div class="right-field">
            <angular-editor
              *ngIf="!isReview"
              formControlName="description"
              [config]="config"
            >
            </angular-editor>
            <div
              *ngIf="isReview"
              class="custom-disable-editor"
              [innerHTML]="f['description'].value"
            ></div>
          </div>
        </div>
        <div class="form-group" *ngIf="!defaultPackage">
          <div class="left-title">
            <label>SKU<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input type="text" class="form-control" formControlName="sku" />
            <div
              *ngIf="
                f['sku'].errors && f['sku'].errors['required'] && isSubmitted
              "
              class="required-note text-danger"
            >
              SKU is required.
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Available Qty<span class="required">*</span></label>
          </div>
          <div class="right-field">
            <input
              type="text"
              class="form-control"
              gfdNumbersOnly
              [allowDecimals]="false"
              formControlName="stock"
            />
            <div
              *ngIf="
                f['stock'].errors &&
                f['stock'].errors['required'] &&
                isSubmitted
              "
              class="required-note text-danger"
            >
              stock is required.
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Delivery Time (days)</label>
          </div>
          <div class="right-field">
            <input
              type="text"
              class="form-control"
              placeholder="7 Days"
              formControlName="deliveryTime"
              gfdNumbersOnly
              [maxlength]="10"
            />
          </div>
        </div>
        <div class="form-group" *ngIf="false">
          <div class="left-title">
            <label>Revisions</label>
          </div>
          <div class="right-field">
            <input
              type="text"
              class="form-control"
              placeholder="4"
              formControlName="revisions"
              gfdNumbersOnly
              [maxlength]="10"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-0" *ngIf="!isReview">
      <div class="bottom-btnrow">
        <button type="button" class="btn t-grey-btn-outline" (click)="reset()">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button type="button" class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
