import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ValidationService } from '@app/core/services';

import { GeneralService } from '@core/services/general.service';

@Component({
  selector: 'app-edit-variant-option-modal',
  templateUrl: './edit-variant-option-modal.component.html',
})
export class EditVariantOptionModalComponent implements OnInit, OnChanges {
  editOptionValueData: any;
  isReview = false;
  taxPercentage: number = 0;
  totalAmount: number;

  @ViewChild('closeMdl') closeMdl: any;

  formData = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    sale_price: new UntypedFormControl('', [ValidationService.required]),
    sku: new UntypedFormControl('', [ValidationService.required]),
    available_stock: new UntypedFormControl('', [
      ValidationService.required,
      Validators.min(1),
    ]),
    barcode: new UntypedFormControl(''),
  });
  formName: string;
  isSubmitted = false;
  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      editOptionValueData: any;
      isReview: boolean;
      isBackorderAllowed: boolean;
      taxPercentage: number;
    },
    public generalService: GeneralService
  ) {
    if (data?.editOptionValueData) {
      this.editOptionValueData = data.editOptionValueData;
    }

    if (data?.isReview) {
      this.isReview = data.isReview;
    }

    if (data?.taxPercentage) {
      this.taxPercentage = data.taxPercentage;
    }
  }

  get f() {
    return this.formData.controls;
  }

  ngOnInit(): void {
    if (this.data.isBackorderAllowed) {
      this.formData.get('available_stock').clearValidators();
      this.formData.get('available_stock').updateValueAndValidity();
    }
    if (this.editOptionValueData) {
      this.formData.patchValue({
        sale_price: this.editOptionValueData.sale_price,
        id: this.editOptionValueData.id,
        name: this.editOptionValueData.name,
        price: this.editOptionValueData.price,
        available_stock: this.editOptionValueData.available_stock,
        sku: this.editOptionValueData.sku,
        barcode: this.editOptionValueData.barcode,
      });
    }
    this.formName = this.formData.controls['name'].value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotalAmount(this.formData.controls['sale_price'].value);
  }

  save(): void {
    this.isSubmitted = true;
    if (this.formData.invalid) {
      return;
    } else {
      this.close(this.formData.getRawValue());
    }
  }

  checkValidPrice() {
    const regularPrice = Number(this.formData.controls['price'].value);
    const salePriceError = this.formData.controls['sale_price']?.errors;
    const salePrice = Number(this.formData.controls['sale_price'].value);
    if (regularPrice > 0 && regularPrice < salePrice) {
      this.formData.controls['sale_price']?.setErrors({
        ...salePriceError,
        salePriceInvalid: true,
      });
    } else {
      if (salePriceError) {
        delete salePriceError['salePriceInvalid'];
      }
      this.formData.controls['sale_price']?.setErrors({
        ...salePriceError,
      });
      this.formData.controls['sale_price'].updateValueAndValidity();
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  reset(): void {
    this.formData.reset();
  }

  calculateTotalAmount(sale_price: any): number {
    const taxRate = this.taxPercentage;
    const totalPrice = Number(sale_price) ? Number(sale_price) : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    return totalAmount > 0 ? totalAmount : 0.0;
  }
}
