import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeneralService } from '@app/core/services';
import { APPLICATION_STATUS } from '@app/shared/model/vendor.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() circlePreview = true;
  @Input() ratio!: string;

  @Input() message = 'PNG,JPG or GIF (max 800x800 px)';
  @Input() allowFileTypes = 'image/png, image/jpg, image/jpeg';
  // @Input() allowFileTypes = '.png, .jpg, .jpeg';
  @Input() canRemove = true;
  @Input() isThumbnailShow = true;

  @Input() set class(val: number) {
    this.imageStatus = val;
  }
  imageUrl = '';
  imageStatus = 0;
  applicationStatus = APPLICATION_STATUS;
  errorMessage = '';

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Highlight the drop area
    this.setDragDropStyles(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Remove the highlight from the drop area
    this.setDragDropStyles(false);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.setDragDropStyles(false);

    const files = event.dataTransfer?.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      this.readAndDisplayImage(selectedFile);
      this.onChange(selectedFile);
      this.onTouched();
    }
  }

  constructor(private generalService: GeneralService) {}

  onChange: (value: any) => void = () => {

  };

  onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.imageUrl = value ?? '';
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFileChange(event: Event): void {
    // const inputElement = event.target as HTMLInputElement;
    // const files = inputElement.files;

    // if (files && files.length > 0) {
    //   const selectedFile = files[0];
    //   this.readAndDisplayImage(selectedFile);
    //   this.onChange(selectedFile);
    // }
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (this.isValidFileType(selectedFile)) {
        this.readAndDisplayImage(selectedFile);
        this.onChange(selectedFile);
        this.errorMessage = '';
      } else {
        this.generalService.displayError('Invalid file type. Please upload an image file.');
      }
    }
  }

  removeImage() {
    if (this.disabled) {
      return;
    }
    this.generalService
      .confirmationDialog('Are You Sure?', `You want to delete this image!`)
      .then((result: any) => {
        if (result.isConfirmed) {
          this.generalService.authorProfilePicRemoved = true;
          this.imageUrl = '';
          this.onChange('');
        }
      });
  }

  private isValidFileType(file: File): boolean {
    const allowedTypes = this.allowFileTypes.split(', ').map(type => type.trim().toLowerCase());
    const fileType = file.type.toLowerCase();
    return allowedTypes.includes(fileType);
  }

  private readAndDisplayImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imageUrl = e.target?.result as string;
    };

    reader.readAsDataURL(file);
  }

  private setDragDropStyles(isDragging: boolean): void {
    // Add or remove styles based on whether the user is dragging a file
    const dropContainer = document.querySelector('.drop-container');

    if (dropContainer) {
      dropContainer.classList.toggle('drag-over', isDragging);
    }
  }
}
