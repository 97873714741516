<div class="modal-body">
  <div class="modal-title">
    <h4>Are you sure?</h4>
    <p>Please confirm on deactivating your account. We'll send you an email.</p>
  </div>
  <div class="change-email-content">
    <form name="deActivateAccount" [formGroup]="deActivateAccount">
      <div class="form-group password-group">
        <label for="">Confirm Password<span class="required">*</span> </label>
        <input
          [type]="showPwd ? 'text' : 'password'"
          class="form-control"
          id="deact-pwd"
          formControlName="password"
        />
        <span class="show-pwd" id="show-hide-pwd"
          ><img
            [src]="
              showPwd
                ? 'assets/images/show-hide-icon.svg'
                : 'assets/images/show-eye-icon.svg'
            "
            alt="pwd-icon"
            (click)="showPwd = !showPwd"
        /></span>
      </div>
      <div
        *ngIf="f['password'].invalid && f['password'].touched"
        class="cross-validation-error-message"
      >
        <div
          *ngIf="f['password'].errors && f['password'].errors['required']"
          class="form-text text-danger"
        >
          Password is required.
        </div>
      </div>
    </form>
  </div>
  <div class="filter-actions-bottom card-box-action">
    <ul>
      <li>
        <a href="javascript: void(0)" data-dismiss="modal" #closeMdl>
          Cancel
        </a>
      </li>
      <li class="last-btn">
        <a href="javascript: void(0)" class="btn t-blue-btn" (click)="save()">
          Deactivate my account
        </a>
      </li>
    </ul>
  </div>
</div>
