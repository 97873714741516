import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { GeneralService } from '@app/core/services';
import { OpenModalConfig } from '@app/shared/directives/open-modal.directive';

import { Select2OptionData } from 'ng-select2';

import { AssignVariantImageModalComponent } from '../assign-variant-image-modal/assign-variant-image-modal.component';
import { EditVariantOptionModalComponent } from '../edit-variant-option-modal/edit-variant-option-modal.component';

@Component({
  selector: 'app-add-product-variant-modal',
  templateUrl: './add-product-variant-modal.component.html',
  styleUrls: ['./add-product-variant-modal.component.scss'],
})
export class AddProductVariantModalComponent implements OnInit {
  @Output() variants = new EventEmitter();
  @Input() variantList: any;
  @Input() oldImages: any;
  @Input() newImages: any;
  @Input() isReview = false;

  isDisplayTaxColumn = false;
  @Input() set taxRequired(value: boolean) {
    this.isDisplayTaxColumn = value;
  }

  taxPercentageValue = 0;
  @Input() set taxPercentage(value: number) {
    this.taxPercentageValue = +value;
  }

  isSubmitted = false;
  @Input() set isFormSubmitted(value: boolean) {
    this.isSubmitted = value;
  }

  isBackorderAllowed = false;
  @Input() set allowBackorders(value: boolean) {
    this.isBackorderAllowed = value;
  }

  isCustomVariantExpand = false;
  optionNameList: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Select',
    },
    {
      id: '1',
      text: 'Size',
    },
    {
      id: '2',
      text: 'Color',
    },
    {
      id: '3',
      text: 'Material',
    },
    {
      id: '4',
      text: 'Style',
    },
  ];
  customVariantName: any = '';
  selectedVariant = '0';
  selectedOption = {
    id: '',
    name: '',
    values: [] as Array<any>,
    isEdit: false,
  };
  optionValue: any = '';
  finalVariantList: any = [];
  allOptionValues: any = [];
  editOptionValueData: any;
  isClickAddVariantOption: any = false;
  optionValueID: any;
  colorCode: any = '#FF0000';
  selectAssignedImgUrl: any = '';

  variantOptionModalData: OpenModalConfig = {
    modalComponent: EditVariantOptionModalComponent,
    modalData: {},
  };

  assignVariantImageModalData: OpenModalConfig = {
    modalComponent: AssignVariantImageModalComponent,
    modalData: {},
  };

  totalPrice: number;

  constructor(public generalService: GeneralService) {}

  ngOnInit(): void {
    if (this.variantList) {
      this.finalVariantList = this.variantList?.optionValues
        ? this.variantList?.optionValues
        : [];
      this.optionNameList =
        this.variantList?.options?.length > 0
          ? this.variantList?.options
          : this.optionNameList;
    }
    if (
      this.variantList?.all?.length > 0 &&
      this.variantList?.all[0]?.name !== ''
    ) {
      this.allOptionValues = this.variantList?.all;
    } else {
      this.getCombinationData();
    }
  }

  addCustomVariantName(): void {
    if (!this.customVariantName) {
      this.generalService.displayError('Please enter variant name.');
      return;
    } else {
      const isDuplicate =
        this.optionNameList.filter(
          (c: any) => c.text === this.customVariantName
        )?.length > 0;
      if (!isDuplicate) {
        this.optionNameList.push({
          id: (this.optionNameList?.length + 1)?.toString(),
          text: this.customVariantName,
        });
        this.isCustomVariantExpand = false;
        this.removeCustomVariantName();
      } else {
        this.generalService.displayError('Name is already exist.');
      }
    }
  }

  removeCustomVariantName(): void {
    this.customVariantName = '';
  }

  removeSeletecVariantName(): void {
    // let selectedVariantIndex = this.optionNameList.findIndex((o: any) => o.id == this.selectedVariant);
    // this.optionNameList.splice(selectedVariantIndex, 1)
    this.selectedVariant = '0';
  }

  changeVariantOptionName(event: any): void {
    this.selectedOption.id = event;
    this.selectedOption.name = this.optionNameList?.filter(
      (o: any) => o.id === event
    )?.[0]?.text;
    this.isClickAddVariantOption = true;
    this.resetOptionValueForm();
  }

  removeOptionValue(index: any): void {
    this.selectedOption?.values?.splice(index, 1);
  }

  addOptionValue(): void {
    if (!this.optionValue) {
      this.generalService.displayError('Please enter option value.');
      return;
    }
    if (this.selectedOption?.values[0]?.id === '') {
      this.selectedOption?.values?.splice(0, 1);
    }
    if (!this.isDuplicateOptionValue(this.optionValue)) {
      this.selectedOption?.values.push({
        id: this.selectedOption?.values?.length + 1,
        name: this.optionValue,
        color_code: this.selectedOption.id === '2' ? this.colorCode : '',
      });
    } else {
      this.generalService.displayError(
        'Same option value not add, Please add different option value name!!'
      );
    }
    this.optionValue = '';
    this.colorCode = '#FF0000';
  }

  resetOptionValueForm(): void {
    this.selectedOption.values = [];
  }

  updateOptionValue(): void {
    if (this.selectedOption.isEdit) {
      for (let a = 0; a < this.finalVariantList.length; a++) {
        const obj = this.finalVariantList[a];
        if (obj.option_name === this.selectedOption.name) {
          obj.option_name = this.selectedOption.name;
          obj.option_values = this.selectedOption.values;
        }
      }
    } else {
      if (!this.selectedOption.id || this.selectedOption.id === '0') {
        this.generalService.displayError('Please Select Variant option type.');
        return;
      } else if (!this.selectedOption.values.length) {
        this.generalService.displayError(
          'Please add variant option for specific type'
        );
        return;
      } else {
        // if selected
        const index = this.finalVariantList.findIndex(
          (f: any) => f.option_id === this.selectedOption.id
        );
        if (index >= 0) {
          const optionValues = this.finalVariantList[index].option_values;
          this.selectedOption?.values.forEach((f, i) => {
            this.finalVariantList[index].option_values.push({
              id: Number(+(optionValues[optionValues.length - 1]?.id || 0) + 1),
              name: f.name,
            });
          });
        } else {
          this.finalVariantList.push({
            id: this.finalVariantList.length + 1,
            option_name: this.selectedOption.name,
            option_id: this.selectedOption.id,
            option_values: this.selectedOption?.values,
          });
        }
      }
    }
    this.selectedVariant = '0';
    this.selectedOption.id = '';
    this.selectedOption.name = '';
    this.selectedOption.isEdit = false;
    this.optionValue = '';
    this.resetOptionValueForm();
    // this.getAllOptionWithValues();
    this.getCombinationData();
  }

  isDuplicateOptionValue(name: string): boolean {
    // const lowerCaseTrimmedName = name.trim().toLowerCase();
    // this.finalVariantList.map((obj: any) => {
    //   obj.option_values.some(
    //     (items) => items.name.trim().toLowerCase() === lowerCaseTrimmedName
    //   );
    // });
    // return this.selectedOption.values.some(
    //   (item) => item.name.trim().toLowerCase() === lowerCaseTrimmedName
    // );

    const lowerCaseTrimmedName = name.trim().toLowerCase();

    // Check in finalVariantList
    if (
      this.finalVariantList.some((obj: any) =>
        obj.option_values.some(
          (items: any) =>
            items.name.trim().toLowerCase() === lowerCaseTrimmedName
        )
      )
    ) {
      return true; // Duplicate found in finalVariantList
    }

    // Check in selectedOption.values
    if (
      this.selectedOption.values.some(
        (item: any) => item.name.trim().toLowerCase() === lowerCaseTrimmedName
      )
    ) {
      return true; // Duplicate found in selectedOption.values
    }

    // No duplicates found
    return false;
  }

  getAllOptionWithValues(): void {
    this.allOptionValues = [];
    for (let i = 0; i < this.finalVariantList.length; i++) {
      const iobj = this.finalVariantList[i];
      for (let j = 0; j < iobj?.option_values?.length; j++) {
        const jobj = iobj?.option_values[j];
        this.allOptionValues.push({
          id: this.allOptionValues.length + 1,
          name: iobj.option_name + ' | ' + jobj.name,
          option_id: Number(iobj.id),
          option_name: iobj.option_name,
          value_id: Number(jobj.id),
          value_name: jobj.name,
          value_color_code: jobj.color_code,
          price: '',
          sale_price: '',
          available_stock: '',
          sku: '',
          barcode: '',
        });
      }
    }
  }

  editVartiant(index: any): void {
    this.selectedOption.values = this.finalVariantList[index]?.option_values;
    this.selectedOption.id = this.finalVariantList[index]?.option_id;
    this.selectedOption.name = this.finalVariantList[index]?.option_name;
    this.selectedOption.isEdit = true;
    this.isClickAddVariantOption = true;
  }

  removeOptionValueVariant(index: any, action: number = 1): void {
    this.generalService
      .confirmationDialog(
        'Are You Sure?',
        `You want to ${action === 1 ? 'Delete' : 'Cancel'}!`
      )
      .then((result: any) => {
        if (result.isConfirmed) {
          this.finalVariantList.splice(index, 1);
          this.getCombinationData();
        }
      });
  }

  getVariantOptionModalData(id: any): OpenModalConfig {
    this.editOptionValueData = this.allOptionValues?.filter(
      (obj: any) => obj.id === id
    )?.[0];
    this.variantOptionModalData = {
      ...this.variantOptionModalData,
      modalData: {
        ...this.variantOptionModalData.modalData,
        isReview: this.isReview,
        editOptionValueData: this.editOptionValueData,
        taxPercentage: this.taxPercentageValue,
        isBackorderAllowed: this.isBackorderAllowed,
      },
    };
    return this.variantOptionModalData;
  }

  scroll(element: HTMLElement): void {
    if (element) {
      const headerHeight = 180;
      const sectionTop = element.getBoundingClientRect().top + window.scrollY;
      const yOffset = sectionTop - headerHeight;

      window.scrollTo({
        top: yOffset,
        behavior: 'smooth',
      });
      this.isClickAddVariantOption = true;
    }
  }

  getUpdatedOptionValueData(data: any): void {
    this.allOptionValues?.map((obj: any) => {
      if (obj?.id === data?.id) {
        obj.price = Number(data.price);
        obj.sale_price = Number(data.sale_price);
        obj.sku = data.sku;
        obj.barcode = data.barcode;
        obj.available_stock = data.available_stock ? data.available_stock : 0;
      }
    });
  }

  emitAllOptionValueData(): void {
    const payload = {
      all: this.allOptionValues,
      options: this.optionNameList,
      optionValues: this.finalVariantList,
    };

    this.variants.emit(payload);
  }

  getAssignedImg(data: any): void {
    if (data) {
      this.allOptionValues?.map((obj: any) => {
        if (obj?.id === data?.optionValueID) {
          obj.img_url = data?.img_url;
          obj.new_img_id = data?.new_img_id;
        }
      });
    }
  }

  calculateTotalAmount(option: any): number {
    const taxRate = this.taxPercentageValue;
    const totalPrice = Number(option.sale_price)
      ? Number(option.sale_price)
      : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    return totalAmount > 0 ? totalAmount : 0.0;
  }

  deleteOptionValue(id, index): void {
    if (index !== -1) {
      this.allOptionValues.splice(index, 1);

      const findIndex = this.finalVariantList.findIndex(
        (ele) => +ele.id === id
      );
      if (findIndex !== -1) {
        this.removeOptionValueVariant(findIndex);
      }
    }
  }
  getAssignVariantImageModalData(id: any, img: any): OpenModalConfig {
    this.optionValueID = id;
    this.selectAssignedImgUrl = img;
    this.assignVariantImageModalData = {
      ...this.assignVariantImageModalData,
      modalData: {
        ...this.assignVariantImageModalData.modalData,
        isReview: this.isReview,
        selectAssignedImgUrl: this.selectAssignedImgUrl,
        optionValueID: this.optionValueID,
        existingImages: this.oldImages,
        selectedProductImages: this.newImages,
      },
    };

    return this.assignVariantImageModalData;
  }

  getCombinationData() {
    const arr: any = [];

    for (let i = 0; i < this.finalVariantList.length; i++) {
      const iobj = this.finalVariantList[i];
      arr.push({
        values: iobj.option_values,
        option_id: Number(iobj.option_id),
        option_name: iobj.option_name,
      });
    }
    const final = this.combinations(arr);

    const finalArr: any[] = [];
    const valueIDs = [];
    final.forEach((f: any) => {
      const valueNames = [];
      const optionValueData: Array<any> = [];
      for (const o in f) {
        valueNames.push(f[o].name);
        valueIDs.push(f[o].id);
        optionValueData.push(f[o]);
      }
      finalArr.push({
        name: valueNames.join('/'),
        optionValueData,
      });
    });

    this.allOptionValues = [];
    for (let j = 0; j < finalArr.length; j++) {
      // const find = this.allOptionValues.find(
      //   (el) => el.name === finalArr[j]?.name
      // );
      // if (!find) {
      //   if (this.finalVariantList.length === 0) {
      //     this.allOptionValues = [];
      //   }
      this.allOptionValues.push({
        id: this.allOptionValues.length + 1,
        name: finalArr[j]?.name,
        price: '',
        sale_price: '',
        available_stock: '',
        sku: '',
        barcode: '',
        optionValueData: finalArr[j]?.optionValueData,
      });
      // }
    }
    console.log('this.allOptionValues', this.allOptionValues);
    this.emitAllOptionValueData();
  }

  combinations(arr: any) {
    return (function recurse(keys) {
      if (!keys.length) return [{}];
      const result = recurse(keys.slice(1));
      const optionValuesFinal = [];
      optionValuesFinal.push({
        option_id: arr.option_id,
      });
      const { option_id, option_name } = arr[keys[0]];
      return arr[keys[0]].values?.reduce(
        (acc: any, value: any) =>
          acc.concat(
            result.map((item: any) =>
              Object.assign({}, item, {
                [keys[0]]: { ...value, option_id, option_name },
              })
            )
          ),
        []
      );
    })(Object.keys(arr));
  }
}
