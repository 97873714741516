import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  StorageService,
  GeneralService,
  ValidationService,
} from '@app/core/services';
import { DashboardService } from '@app/core/services/dashboard.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-report-template-setting-modal',
  templateUrl: './report-template-setting-modal.component.html',
})
export class ReportTemplateSettingModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output() dataEvent = new EventEmitter<any>();
  @Output() modalClose = new EventEmitter();
  @Input() set reportType(val) {
    if (val) {
      this.fildForm.get('fildType').setValue(val);
      this.selectTempField(val);
    }
  }

  searchKey = '';
  selectedTempFields: any = [];
  fildForm!: FormGroup;
  reportTemplatelList: any = [];
  copyReportTemplateList: any = [];
  userId!: string;
  isAllSelected = false;
  reportNameList: Array<Select2OptionData> = [];
  selectedField = new Set();

  isAnyFieldSelected = false;

  constructor(
    private dashboardService: DashboardService,
    private storageService: StorageService,
    private generalService: GeneralService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.prepareForm();
    this.customReportTemplatData();
    this.userId = this.storageService.getCookie('userID');
  }

  prepareForm(): void {
    this.fildForm = new UntypedFormGroup({
      fildType: new UntypedFormControl('', ValidationService.required),
    });
  }

  selectTempField(event: any): void {
    this.getReportFields(event);
  }

  customReportTemplatData() {
    this.dashboardService
      .getCustomReportTemplats(this.userId)
      .subscribe((res) => {
        this.reportNameList = res.data.map((el: any) => ({
          text: el.template_name,
          id: el._id,
        }));

        this.fildForm.get('fildType').setValue(this.reportNameList[0].id);
      });
  }

  getReportFields(templateId: string): void {
    const vendorID = this.storageService.getCookie('userID');
    const payload = {
      template_id: templateId,
    };

    if (payload.template_id) {
      this.dashboardService
        .getreportTemplateFields(payload)
        .subscribe((res) => {
          this.reportTemplatelList = res.data;
          this.copyReportTemplateList = JSON.parse(
            JSON.stringify(this.reportTemplatelList)
          );
          this.isAnyFieldSelected = this.reportTemplatelList?.some(
            (item) => item.is_selected
          );

          this.copyReportTemplateList.forEach((element) => {
            if (element.is_selected) {
              this.selectedField.add(element._id);
            }
          });
        });
    }
  }

  search(): void {
    this.reportTemplatelList = this.copyReportTemplateList.filter((el: any) =>
      el.template_field
        ?.toLocaleLowerCase()
        ?.includes(this.searchKey?.toLocaleLowerCase())
    );

    this.reportTemplatelList = JSON.parse(
      JSON.stringify(this.reportTemplatelList)
    );

    this.reportTemplatelList = this.reportTemplatelList.map((element) => {
      if (this.selectedField.has(element._id)) {
        element.is_selected = true;
        return element;
      } else {
        element.is_selected = false;
      }
      return element;
    });
  }

  save(): void {
    this.isAnyFieldSelected = this.reportTemplatelList?.some(
      (item) => item.is_selected
    );

    if (this.isAnyFieldSelected) {
      const vendorID = this.storageService.getCookie('userID');
      const fildType = this.fildForm;
      const payload = {
        template_id: fildType.value.fildType,
        field_ids: this.reportTemplatelList,
      };
      this.dashboardService
        .setReportTemplate(payload, vendorID)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          if (response) {
            this.generalService.displaySuccess(response.message);
          }
        });
      this.closeModal();
      const dataToSend = fildType.value.fildType;
      this.dataEvent.emit(dataToSend);
    }
  }

  get f() {
    return this.fildForm.controls;
  }

  isChecked(id: any, value: boolean): void {
    console.log('radio button =====>', id);
    if (this.reportTemplatelList) {
      this.isAllSelected = true;
    }
    this.isAnyFieldSelected = true;
    if (value) {
      this.selectedField.add(id);
    } else {
      this.selectedField.delete(id);
    }
  }

  removeFild(index: any): void {
    this.reportTemplatelList[index].is_selected = false;
    this.selectedField.delete(this.reportTemplatelList[index]._id);
  }

  reset(): void {
    this.reportTemplatelList = JSON.parse(
      JSON.stringify(this.copyReportTemplateList)
    );
    this.searchKey = '';
    this.isAllSelected = false;
  }

  closeModal(): void {
    this.closeMdl?.nativeElement?.click();
    this.modalClose.emit();
  }
}
