import { Component, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs';

import { GeneralService } from '../../../core/services/general.service';
import { VendorService } from '../../../core/services/vendor.service';

import { SubscriptionDisposerComponent } from '../../helpers/subscription-disposer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  categoryList: any;
  currentURL: any;
  redirectedURL: any;
  currentYear: any = '';

  constructor(
    public generalService: GeneralService,
    private vendorService: VendorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getHomeCategories();
    this.currentURL = window.location.href;
    this.currentYear = new Date().getFullYear();
  }

  getHomeCategories(): void {
    this.vendorService
      .getHomeCategories()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.categoryList = response?.data;
          }
        },
        (error) => {
          this.generalService.displayWarning(error?.error?.message);
        }
      );
  }

  gotoCategory(catID: any): void {
    if (this.currentURL.includes('localhost')) {
      this.redirectedURL =
        'http://php.dev.drcsystems.ooo/meydan/meydan-marketplace/users/home/services?cid=' +
        catID;
    } else if (this.currentURL.includes('php.dev.drcsystems.ooo')) {
      this.redirectedURL =
        'http://php.dev.drcsystems.ooo/meydan/meydan-marketplace/users/home/services?cid=' +
        catID;
    } else if (
      this.currentURL.includes('meydanplus.uaenorth.cloudapp.azure.com')
    ) {
      this.redirectedURL =
        'https://meydanplus.uaenorth.cloudapp.azure.com/users/home/services?cid=' +
        catID;
    }
  }
}
