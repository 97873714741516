import { Injectable } from '@angular/core';

import { get } from 'lodash';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandler {
  constructor() {}

  public handle(
    err: any,
    specificErrorHandler?: (err: any) => { title: string; message: string }
  ): Observable<never> {
    let title: string;
    let msg: string;
    let options: Partial<any> = {};

    const details = get(err.error, 'error.details');
    if (details) {
      title = `Error Code: ${err.status}`;
      msg = `Details:<br/>${details
        .map(({ message }: any) => message)
        .join('<br/>')}`;
      options = { enableHtml: true };
    } else if (specificErrorHandler) {
      const specificErrorHandlerResult = specificErrorHandler(err);
      if (specificErrorHandlerResult) {
        ({ title, message: msg } = specificErrorHandler(err));
      } else {
        ({ title, msg } = this.baseErrorToDisplay(err));
      }
    } else {
      ({ title, msg } = this.baseErrorToDisplay(err));
    }

    return throwError(err);
  }

  private baseErrorToDisplay(err: any): { title: string; msg: string } {
    return {
      title: `Error Code: ${err.status}`,
      msg: `Message: ${get(err.message, 'error.message', err.statusText)}`,
    };
  }
}
