import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-verify-email-success-modal',
  templateUrl: './verify-email-success-modal.component.html',
})
export class VerifyEmailSuccessModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closeMdl(): void {
    this.closeModal.emit();
  }
}
