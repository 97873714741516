import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  StorageService,
  GeneralService,
  ValidationService,
} from '@app/core/services';
import { WalletService } from '@app/core/services/wallet.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-request-payout-modal',
  templateUrl: './request-payout-modal.component.html',
})
export class RequestPayoutModalComponent
  extends SubscriptionDisposerComponent
  implements OnChanges
{
  @Input() bankID: any;
  @ViewChild('closeModal') closeModal: any;
  @Output() closePayoutModal = new EventEmitter();

  amount: any = '';
  beneficiaryList: Array<Select2OptionData> = [];
  bankDetails: any;
  beneficiary: any;

  payoutForm = new UntypedFormGroup({
    amount: new UntypedFormControl('', [ValidationService.required]),
    beneficiary: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    private storageService: StorageService,
    public generalService: GeneralService,
    private walletService: WalletService
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.bankID) {
      this.getBankDetails();
      this.getBeneficiaryList();
    }
  }

  reset(): void {
    this.payoutForm.reset();
  }

  getBankDetails(): void {
    this.walletService
      .getBankAccountDetails(this.bankID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankDetails = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  getBeneficiaryList(): void {
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
    };
    this.walletService
      .getBeneficiaryList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.beneficiaryList = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  requestPayout(): void {
    if (this.payoutForm.invalid) {
      this.payoutForm.markAllAsTouched();
      return;
    } else {
      const payload = {
        seller: this.storageService.getCookie('userID'),
        bank_account: this.bankID,
        payout_amount: this.payoutForm?.value?.amount,
        beneficiary: this.payoutForm?.value?.beneficiary,
      };
      this.walletService
        .requestPayout(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.closeModal?.nativeElement?.click();
              this.closePayoutModal?.emit();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  get f() {
    return this.payoutForm.controls;
  }
}
