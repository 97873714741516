<div
  class="custom-dialog-modal modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">EDIT {{ formName }}</h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="edit-variant-form" [formGroup]="formData">
        <div class="edit-form-row">
          <div class="form-group">
            <div class="left-title">
              <label>SKU<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input type="text" class="form-control" formControlName="sku" />
              <div
                *ngIf="
                  f['sku'].errors && f['sku'].errors['required'] && isSubmitted
                "
                class="required-note text-danger"
              >
                SKU is required.
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Regular Price</label>
            </div>
            <div class="right-field price-field-control">
              <span class="unit">AED</span>
              <input
                type="text"
                gfdNumbersOnly
                [disabled]="isReview"
                class="form-control"
                placeholder="Regular Price"
                formControlName="price"
                (ngModelChange)="checkValidPrice()"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Sale Price<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input
                gfdNumbersOnly
                [maxAllowedValue]="
                  formData.controls['price'].value > 0
                    ? formData.controls['price'].value
                    : undefined
                "
                type="text"
                class="form-control"
                placeholder="Sale Price"
                formControlName="sale_price"
                (ngModelChange)="checkValidPrice()"
              />
              <div
                *ngIf="
                  f['sale_price'].errors &&
                  f['sale_price'].errors['required'] &&
                  isSubmitted
                "
                class="required-note text-danger"
              >
                Sale Price is required.
              </div>
              <div
                *ngIf="
                  f['sale_price'].errors &&
                  f['sale_price'].errors['salePriceInvalid'] &&
                  isSubmitted
                "
                class="required-note text-danger"
              >
                Sale price must be lower then regular price.
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Tax</label>
            </div>
            <div class="right-field price-field-control">
              <span class="unit">AED</span>
              <input
                gfdNumbersOnly
                type="text"
                [disabled]="isReview"
                class="form-control"
                [(ngModel)]="taxPercentage"
                [ngModelOptions]="{ standalone: true }"
                [readOnly]="true"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Total Price</label>
            </div>
            <div class="right-field price-field-control">
              <span class="unit">AED</span>
              <input
                gfdNumbersOnly
                type="text"
                [readOnly]="true"
                [disabled]="isReview"
                class="form-control"
                [value]="
                  calculateTotalAmount(formData.controls['sale_price'].value)
                "
              />
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label
                >Available<span
                  *ngIf="!data.isBackorderAllowed"
                  class="required"
                  >*</span
                ></label
              >
            </div>
            <div class="right-field">
              <input
                gfdNumbersOnly
                type="text"
                [disabled]="isReview"
                class="form-control"
                formControlName="available_stock"
              />
              <div
                *ngIf="
                  f['available_stock'].errors &&
                  f['available_stock'].errors['required'] &&
                  isSubmitted
                "
                class="required-note text-danger"
              >
                Stock is required.
              </div>
              <div
                *ngIf="
                  f['available_stock'].errors &&
                  !f['available_stock'].errors['required'] &&
                  f['available_stock'].errors['min'] &&
                  isSubmitted
                "
                class="required-note text-danger"
              >
                Stock must be greater than zero.
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Barcode</label>
            </div>
            <div class="right-field">
              <input
                type="text"
                [disabled]="isReview"
                class="form-control"
                formControlName="barcode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="bottom-btnrow" *ngIf="!isReview">
        <button type="button" class="btn t-grey-btn-outline" (click)="reset()">
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button type="button" class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
