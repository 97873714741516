import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  StorageService,
  GeneralService,
  ValidationService,
} from '@app/core/services';
import { WalletService } from '@app/core/services/wallet.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-bank-account-modal',
  templateUrl: './add-bank-account-modal.component.html',
})
export class AddBankAccountModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output('update') updateAccountDetails = new EventEmitter();

  bankNameList: any = [];

  bankForm = new UntypedFormGroup({
    bank: new UntypedFormControl(0, [ValidationService.required]),
    accountNumber: new UntypedFormControl('', [ValidationService.required]),
    name: new UntypedFormControl('', [ValidationService.required]),
    iban: new UntypedFormControl('', [ValidationService.required]),
    swiftCode: new UntypedFormControl(''),
    setAsDefault: new UntypedFormControl(false),
  });

  constructor(
    private storageService: StorageService,
    public generalService: GeneralService,
    private walletService: WalletService
  ) {
    super();
  }

  get f() {
    return this.bankForm.controls;
  }

  ngOnInit(): void {
    this.getBanks();
  }

  save(): void {
    if (this.bankForm.invalid) {
      this.bankForm.markAllAsTouched();
      return;
    } else {
      const { bank, accountNumber, name, iban, swiftCode, setAsDefault } =
        this.bankForm.value;
      const payload = {
        bank: bank,
        account_number: accountNumber,
        name: name,
        iban: iban,
        swift_code: swiftCode,
        set_default: setAsDefault,
        seller: this.storageService.getCookie('userID'),
      };
      this.walletService
        .setBankAccount(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.updateAccountDetails.emit();
              this.closeMdl.nativeElement.click();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(): void {
    this.bankForm.reset();
  }

  getBanks(): void {
    this.walletService
      .getBanks()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankNameList = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }
}
