// dynamic-confirmation-dialog.directive.ts
import {
  Directive,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { GeneralService } from '@app/core/services';

@Directive({
  selector: '[confirmationDialog]',
})
export class ConfirmationDialogDirective {
  @Input() action: number;
  @Input() title: string = 'Are You Sure?';
  @Input() text: string = 'You want to';
  @Input() confirmButtonText: string = 'Yes';
  @Input() cancelButtonText: string = 'No';
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private generalService: GeneralService) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();

    this.generalService
      .confirmationDialog(this.title, `${this.text}`, this.confirmButtonText)
      .then((result: any) => {
        if (result.isConfirmed) {
          // Emit the confirmation event
          this.confirmed.emit();
        }
      });
  }
}
