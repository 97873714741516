/* eslint-disable jsdoc/no-types */

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[gfdNumbersOnly]',
})
export class NumbersOnlyDirective implements OnInit {
  @Input() allowDecimals = true;

  @Input() allowSign = false;
  @Input() decimalLength!: number;
  @Input() maxAllowedValue!: number;
  @Input() minAllowedValue!: number;
  // eslint-disable-next-line @typescript-eslint/quotes
  @Input() decimalSeparator: "'" | '.' | ',' | '/' = '.';
  @Output() valuePasted: EventEmitter<any> = new EventEmitter<any>();

  previousValue = '';

  /**
   * Class constructor
   *
   * @param hostElement
   */
  constructor(@Self() @Optional() public controlDir: NgControl) {}

  /**
   * Event handler for host's change event
   *
   * @param e
   */
  @HostListener('change', ['$event']) onChange(e: any) {
    if (this.validateValue(e.target.value)) {
      let value = e.target.value;

      if (
        value.length === 1 &&
        this.minAllowedValue === 0 &&
        value?.charAt(0) === this.decimalSeparator
      ) {
        e.target.value = 0;
        return;
      }

      const firstCharacter = value?.charAt(0);
      if (firstCharacter === this.decimalSeparator) {
        value = 0 + value;
      }

      const lastCharacter = value?.charAt(value.length - 1);
      if (lastCharacter === this.decimalSeparator) {
        value = value + 0;
      }
      e.target.value = value;
      return;
    }
  }

  /**
   * Event handler for host's paste event
   *
   * @param e
   */
  @HostListener('paste', ['$event']) onPaste(e: any) {
    // get and validate data from clipboard
    const pastedText = e.clipboardData.getData('text/plain');

    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    const currentValue = e.target.value;
    e.preventDefault();
    if (this.validateValue(pastedText)) {
      const maxLength = parseInt(e.target.getAttribute('maxlength'), 10);
      const currentLength = currentValue.length;
      const remainingLength = maxLength - currentLength;
      const selectedTextLength = selectionEnd - selectionStart;
      const pasteLength =
        pastedText.length > remainingLength + selectedTextLength
          ? remainingLength + selectedTextLength
          : pastedText.length;
      //set pasted value to form control

      if (this.maxAllowedValue) {
        if (this.maxAllowedValue >= +pastedText) {
          e.target.value =
            currentValue.slice(0, selectionStart) +
            pastedText.slice(0, pasteLength) +
            currentValue.slice(selectionEnd);

          //set cursor at the end of pasted text
          e.target.setSelectionRange(
            selectionStart + pasteLength,
            selectionStart + pasteLength
          );
          this.valuePasted.emit(e.target.value);
        }
      } else {
        e.target.value =
          currentValue.slice(0, selectionStart) +
          pastedText.slice(0, pasteLength) +
          currentValue.slice(selectionEnd);

        //set cursor at the end of pasted text
        e.target.setSelectionRange(
          selectionStart + pasteLength,
          selectionStart + pasteLength
        );
        this.valuePasted.emit(e.target.value);
      }

      if (this.controlDir && this.controlDir.control) {
        this.controlDir.control.setValue(e.target.value);
      }
    }
  }

  /**
   * Event handler for host's keydown event
   *
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    const cursorPosition: number = e.target.selectionStart;
    const originalValue: string = e.target.value;
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator);

    // allowed keys apart from numeric characters
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Tab',
      'Enter',
    ];

    // for restricting removing . if max val set
    if (
      this.maxAllowedValue &&
      key === 'Backspace' &&
      originalValue.charAt(cursorPosition - 1) === '.' &&
      +(
        originalValue.substring(0, cursorPosition - 1) +
        originalValue.substring(cursorPosition)
      ) > this.maxAllowedValue
    ) {
      e.preventDefault();
      return;
    }

    // when decimals are allowed, add
    // decimal separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      if (!this.decimalSeparator) {
        allowedKeys.push('.');
      } else {
        allowedKeys.push(this.decimalSeparator);
      }
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator =
      originalValue?.charAt(0) !== this.decimalSeparator;
    if (
      this.allowSign &&
      !signExists &&
      firstCharacterIsSeparator &&
      cursorPosition === 0
    ) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    let updateVal = e.target.value || '';
    if (updateVal) {
      updateVal =
        updateVal.substring(0, cursorPosition) +
        key +
        updateVal.substring(cursorPosition);
    } else {
      updateVal += key;
    }
    const isNumber =
      new RegExp('^[0-9]*$').test(key) &&
      new RegExp(this.regex).test(updateVal);
    // if (!this.maxAllowedValue) {
    //   this.maxAllowedValue = null;
    // }
    /**
     * @todo
     * we have to check if we need above logic or not
     */
    if (
      (isNumber ||
        (e.target.value.charAt(0) === '.' && e.target.value.length < 3)) &&
      (!+this.minAllowedValue || +updateVal >= +this.minAllowedValue) &&
      (!(+this.maxAllowedValue >= 0) || +updateVal <= +this.maxAllowedValue)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }

  ngOnInit() {
    if (!(this.decimalLength >= 0)) {
      this.decimalLength = 2;
    }
  }

  /**
   * Test whether value is a valid number or not
   *
   * @param value
   */
  validateValue(value: string) {
    // choose the appropiate regular expression

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value?.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value?.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      value = value + 0;
    }

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const valid: boolean = value ? new RegExp(this.regex).test(value) : true;
    return valid;
  }

  /**
   * Get key's name
   *
   * @param e
   */
  getName(e: any) {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-';
          case 191:
            return '/'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }

  get regex() {
    if (!this.allowDecimals && !this.allowSign) {
      return '^[0-9]*$';
    }
    if (!this.allowDecimals && this.allowSign) {
      return '^-?[0-9]+$';
    }
    if (this.allowDecimals && !this.allowSign) {
      return `^[0-9]+(\\${this.decimalSeparator}[0-9]{0,${this.decimalLength}}){0,1}$`;
    }
    if (this.allowDecimals && this.allowSign) {
      return `^-?[0-9]+(\\${this.decimalSeparator}[0-9]{0,${this.decimalLength}}){0,1}$`;
    } else {
      return '';
    }
  }
}
