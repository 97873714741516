// export const environment = {
//   production: false,
//   baseApiUrl: 'https://bbwapi-dev.mplatformtech.com/v2/',
//   backendDomain: 'https://bbwapi-dev.mplatformtech.com/',
//   googleKey: 'AIzaSyBBoylO2TyrZGngh00NHXCG6pwr46f4L7Q',
//   googleMapUrl:
//     'https://maps.googleapis.com/maps/api/js?v=weekly&key=${key}&libraries=visualization,places,drawing,marker,geometry&v=weekly&callback=Function.prototype',
// };

export const environment = {
  production: false,
  baseApiUrl: 'https://api.bbwuae.com/v2/',
  backendDomain: 'https://api.bbwuae.com',
  googleKey: 'AIzaSyBBoylO2TyrZGngh00NHXCG6pwr46f4L7Q',
  googleMapUrl:
    'https://maps.googleapis.com/maps/api/js?v=weekly&key=${key}&libraries=visualization,places,drawing,marker,geometry&v=weekly&callback=Function.prototype',
};

// need to update google key & google map url
