<div class="filter-record-div">
  <button type="button" class="btn t-primary-btn filter-btn">
    <img src="assets/images/filter-icon-blue.svg" alt="filter" /> Filter
  </button>
  <div class="filter-content" [formGroup]="filterForm">
    <div class="edit-form-row">
      <div class="form-group">
        <div class="left-title">
          <h6>Period</h6>
        </div>
        <div class="right-field">
          <div class="filter-selection">
            <div class="select-item">
              <ng-select2
                class="select2-control"
                name=""
                id="period"
                [data]="periodList"
                formControlName="period"
                [placeholder]="'Select'"
                (valueChanged)="onPeriodChanged($event)"
              >
              </ng-select2>
            </div>
            <div class="select-item">
              <div class="form-floating">
                <span class="floating-label">From</span>
                <input
                  type="date"
                  class="form-control date-time-control"
                  (keydown)="generalService.disableDate()"
                  (change)="changeDate(1)"
                  max="{{ todate | date : 'yyyy-MM-dd' }}"
                  formControlName="start_date"
                />
              </div>
            </div>
            <div class="select-item">
              <div class="form-floating">
                <span class="floating-label">To</span>
                <input
                  type="date"
                  class="form-control date-time-control"
                  (keydown)="generalService.disableDate()"
                  (change)="changeDate(2)"
                  min="{{ fromDate | date : 'yyyy-MM-dd' }}"
                  formControlName="end_date"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="left-title">
          <h6>Order Status</h6>
        </div>
        <div class="right-field">
          <a
            href="javascript:void(0)"
            class="select-all"
            (click)="onSelectAll(true)"
            >Select All</a
          >
          <div class="select-multiple-checkbox">
            <div class="multiple-checkbox-group">
              <div class="c-checkbox" *ngFor="let status of statusList">
                <input
                  type="checkbox"
                  name=""
                  [id]="'checkbox' + status.id"
                  [checked]="status.checked"
                  (change)="status.checked = !status.checked"
                />
                <label [for]="'checkbox' + status.id">{{ status.text }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btnrow">
        <button
          type="button"
          class="btn t-grey-btn-outline"
          (click)="resetForm()"
        >
          <img src="assets/images/reset-icon.svg" alt="send" /> RESET
        </button>
        <button type="button" class="btn t-primary-btn" (click)="apply()">
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>
  </div>
</div>
