<!--Select-->
<div class="card-box t-card-primary" *ngIf="!isReview">
  <div class="card-box-header">
    <h4 class="card-title">Variants</h4>
  </div>
  <div class="card-box-body">
    <div class="edit-form-row full-form-row">
      <!--Options Name Config-->
      <div class="form-group">
        <div class="left-title">
          <h6>Option Name</h6>
        </div>
        <div class="right-field">
          <!--Selected-->
          <div class="field-with-action" *ngIf="!isCustomVariantExpand">
            <ng-select2
              *ngIf="optionNameList.length > 0"
              class="select2-control"
              style="width: 100%"
              name=""
              id="optionNameID"
              [data]="optionNameList"
              [placeholder]="'Select'"
              [(ngModel)]="selectedVariant"
              (valueChanged)="changeVariantOptionName($event)"
            >
            </ng-select2>
            <button
              *ngIf="selectedOption.id === '1' || selectedOption.id === '2'"
              type="button"
              class="btn field-remove-btn"
              confirmationDialog
              [action]="1"
              [text]="'You want to delete this variant! '"
              [title]="'Are you sure?'"
              [confirmButtonText]="'Delete'"
              [cancelButtonText]="'Cancel'"
              (confirmed)="removeSeletecVariantName()"
            >
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </button>
          </div>
          <!--Custom-->
          <div class="field-with-action" *ngIf="isCustomVariantExpand">
            <div class="tags-control" style="width: 100%">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="customVariantName"
                placeholder="Enter custom variant name"
              />
              <a
                href="javascript: void(0)"
                class="add-tags"
                (click)="addCustomVariantName()"
              >
                <img src="assets/images/plus-icon.svg" alt="plus-icon" />
              </a>
            </div>
            <button
              type="button"
              class="btn field-remove-btn"
              confirmationDialog
              [action]="1"
              [text]="'You want to delete this image! '"
              [title]="'Are you sure?'"
              [confirmButtonText]="'Delete'"
              [cancelButtonText]="'Cancel'"
              (confirmed)="removeCustomVariantName()"
            >
              <img src="assets/images/trash-icon.svg" alt="trash" />
            </button>
          </div>
          <div class="mt-4">
            <a
              (click)="isCustomVariantExpand = !isCustomVariantExpand"
              href="javascript: void(0)"
              class="add-link-sm"
            >
              <span class="icon">
                <img
                  src="{{
                    !isCustomVariantExpand
                      ? 'assets/images/plus-icon.svg'
                      : 'assets/images/minus-icon.svg'
                  }}"
                  alt="plus-icon"
                />
              </span>
              Add custom variant
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Selected Variant list with options-->
<div class="card-box t-card-primary" *ngIf="finalVariantList?.length > 0">
  <div class="card-box-header">
    <h4 class="card-title">Variants</h4>
  </div>
  <div class="card-box-body">
    <div
      class="varient-type-group"
      *ngFor="let v of finalVariantList; let index = index"
    >
      <div class="varient-info">
        <h6>{{ v?.option_name }}</h6>
        <div class="varient-list">
          <ul>
            <li *ngFor="let o of v.option_values">
              {{ o?.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="varient-edit" *ngIf="!isReview">
        <a
          href="javascript: void(0)"
          class="btn t-grey-btn-outline edit-btn mr-1"
          (click)="editVartiant(index)"
          >EDIT</a
        >
        <button
          type="button"
          confirmationDialog
          [action]="1"
          [text]="'You want to delete this image! '"
          [title]="'Are you sure?'"
          [confirmButtonText]="'Delete'"
          [cancelButtonText]="'Cancel'"
          (confirmed)="removeOptionValueVariant(index)"
          class="btn t-danger-btn-outline edit-btn"
        >
          <img src="assets/images/trash-icon-red.svg" alt="trash" class="m-0" />
        </button>
      </div>
    </div>
    <div class="add-custom-package">
      <a
        *ngIf="!isReview"
        href="javascript: void(0)"
        class="add-link-sm"
        (click)="scroll(aa)"
      >
        <span class="icon"
          ><img src="assets/images/plus-icon.svg" alt="plus-icon"
        /></span>
        Add variant option
      </a>
    </div>
    <div class="varient-product-table custom-table">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Variant</th>
              <th scope="col">Price</th>
              <th scope="col">Available</th>
              <th scope="col">SKU</th>
              <th scope="col">Barcode</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let o of allOptionValues; let ind = index">
              <td>
                <div
                  class="thumb-img"
                  appOpenModal
                  [config]="
                    getAssignVariantImageModalData(o.id, 1, '', o?.img_url)
                  "
                  (onModalClose)="getPackageAssignedImg($event)"
                >
                  <img
                    [src]="
                      o.img_url ? o.img_url : 'assets/images/small-thumb.png'
                    "
                    alt="thumb-img"
                  />
                </div>
              </td>
              <td class="text-truncate">
                {{ o.name }}
              </td>
              <td>
                <div class="price-field-control">
                  <span class="unit">AED</span>
                  <input
                    type="text"
                    [disabled]="isReview"
                    gfdNumbersOnly
                    [(ngModel)]="o.price"
                    class="form-control"
                    (input)="emitAllOptionValueData()"
                  />
                </div>
              </td>
              <td>
                <input
                  type="text"
                  [disabled]="isReview"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  style="width: 100px"
                  gfdNumbersOnly
                  [(ngModel)]="o.available_stock"
                />
              </td>
              <td>
                <input
                  type="text"
                  [disabled]="isReview"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  style="width: 100px"
                  [(ngModel)]="o.sku"
                />
              </td>
              <td width="10%">
                <input
                  type="text"
                  [disabled]="isReview"
                  class="form-control"
                  (input)="emitAllOptionValueData()"
                  style="width: 100px"
                  [(ngModel)]="o.barcode"
                />
              </td>
              <td>
                <a
                  href="javascript: void(0)"
                  class="btn t-grey-btn-outline edit-btn"
                  appOpenModal
                  [config]="getVariantOptionModalData(o.id)"
                  (onModalClose)="getUpdatedOptionValueData($event)"
                  >{{ isReview ? 'VIEW' : 'EDIT' }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!--Variant selected update options-->
<div class="card-box t-card-primary" #aa id="aa">
  <!--Custome package variants-->
  <ng-container *ngIf="!isReview">
    <div
      class="card-box-header"
      *ngIf="isClickAddVariantOption && selectedVariant > 2"
    >
      <h4 class="card-title">Add variant options</h4>
    </div>
    <div
      class="card-box-body"
      *ngIf="isClickAddVariantOption && selectedVariant > 2"
    >
      <div class="edit-form-row full-form-row">
        <div class="form-group">
          <div class="left-title">
            <h6>Option Name</h6>
          </div>
          <div class="right-field">
            <div class="field-with-action">
              <input
                type="text"
                class="form-control"
                [disabled]="true"
                [(ngModel)]="selectedOption.name"
                placeholder="Select Option Name"
              />
              <a href="javascript: void(0)" class="btn field-remove-btn d-none">
                <img src="assets/images/trash-icon.svg" alt="trash" />
              </a>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="left-title">
            <h6>Option Value</h6>
          </div>
          <div class="right-field">
            <div
              class="field-with-action"
              *ngFor="let o of selectedOption?.values; let ind = index"
            >
              <input
                *ngIf="selectedOption?.id != '2'"
                type="text"
                class="form-control"
                [(ngModel)]="o.name"
                placeholder="Option value"
              />
              <div *ngIf="selectedOption?.id == '2'" class="color-pick-control">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="o.name"
                  placeholder="Option value"
                />
                <span class="color-picker-item">
                  <input
                    type="color"
                    id="colorPick"
                    [(ngModel)]="o.color_code"
                  />
                </span>
              </div>
              <button
                type="button"
                class="btn field-remove-btn"
                confirmationDialog
                [action]="1"
                [text]="'You want to delete this image! '"
                [title]="'Are you sure?'"
                [confirmButtonText]="'Delete'"
                [cancelButtonText]="'Cancel'"
                (confirmed)="removeOptionValue(ind)"
              >
                <img src="assets/images/trash-icon.svg" alt="trash" />
              </button>
            </div>
            <div class="field-with-action">
              <div class="tags-control" style="width: 100%">
                <input
                  *ngIf="selectedOption.id != '2'"
                  type="text"
                  class="form-control"
                  [(ngModel)]="optionValue"
                  placeholder="Add another value"
                />
                <div
                  *ngIf="selectedOption.id == '2'"
                  class="color-pick-control"
                >
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="optionValue"
                  />
                  <span class="color-picker-item">
                    <input
                      type="color"
                      id="colorPick"
                      [(ngModel)]="colorCode"
                    />
                  </span>
                </div>
                <a
                  href="javascript: void(0)"
                  class="add-tags"
                  (click)="addOptionValue()"
                >
                  <img src="assets/images/plus-icon.svg" alt="plus-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btnrow mt-5">
        <button
          type="button"
          class="btn t-grey-btn-outline"
          (click)="resetOptionValueForm()"
        >
          <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
        </button>
        <button
          type="button"
          class="btn t-primary-btn"
          (click)="updateOptionValue()"
        >
          <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
        </button>
      </div>
    </div>

    <!--Package + Add ons-->
    <div
      class="card-box-header"
      *ngIf="
        (isClickAddVariantOption &&
          (selectedVariant == 2 || selectedVariant == 1)) ||
        isEditPackageName ||
        selectedVariant == 2 ||
        selectedVariant == 1
      "
    >
      <h4 class="card-title">
        Package {{ isEditPackageName ? 'Edit' : packages?.length + 1 }}
      </h4>
    </div>
    <div
      class="card-box-body"
      *ngIf="
        (isClickAddVariantOption &&
          (selectedVariant == 2 || selectedVariant == 1)) ||
        isEditPackageName ||
        selectedVariant == 2 ||
        selectedVariant == 1
      "
    >
      <div class="edit-form-row full-form-row" id="aa" #aa>
        <div class="form-group">
          <div class="left-title">
            <h6>Package Name</h6>
          </div>
          <div class="right-field">
            <div class="field-with-action">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="package.name"
              />
              <!-- <a href="javascript: void(0)" class="btn field-remove-btn">
                              <img src="assets/images/trash-icon.svg" alt="trash">
                          </a> -->
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="selectedVariant == 2">
          <div class="left-title">
            <h6>Addon Name</h6>
          </div>
          <div class="right-field">
            <div class="field-with-action">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="package.addonName"
              />
              <!-- <a href="javascript: void(0)" class="btn field-remove-btn">
                              <img src="assets/images/trash-icon.svg" alt="trash">
                          </a> -->
            </div>
          </div>
        </div>
        <div class="form-group bottom-btnrow">
          <button
            type="button"
            class="btn t-grey-btn-outline"
            (click)="resetPackageForm()"
          >
            <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
          </button>
          <button
            type="button"
            class="btn t-primary-btn"
            (click)="addPackage()"
          >
            <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Packages with custom fields-->
<div class="card-box t-card-primary" *ngIf="packages.length > 0">
  <div *ngFor="let p of packages; let pindex = index">
    <div class="card-box-header">
      <h4 class="card-title">
        Package {{ isEditPackageName ? p.id : pindex + 1 }}
      </h4>
      <a
        *ngIf="!isReview"
        class="btn t-danger-btn-outline edit-btn"
        confirmationDialog
        [action]="1"
        [title]="'Are you sure?'"
        [text]="'You want to delete this package!'"
        [confirmButtonText]="'Delete'"
        [cancelButtonText]="'Cancel'"
        (confirmed)="deletePackage(pindex)"
      >
        <img src="assets/images/trash-icon-red.svg" class="m-0" alt="trash" />
      </a>
    </div>
    <div class="card-box-body">
      <div class="varient-type-group">
        <div class="varient-info">
          <h6>Package Name</h6>
          <div class="varient-list">
            <ul>
              <li>
                {{ p.name }}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="!isReview" class="varient-edit" (click)="scroll(aa)">
          <a
            href="javascript: void(0)"
            (click)="editPackage(p, pindex)"
            class="btn t-grey-btn-outline edit-btn mr-1"
            >EDIT</a
          >
        </div>
      </div>
      <div class="varient-type-group" *ngIf="p?.variantType == 2">
        <div class="varient-info">
          <h6>Addon Name</h6>
          <div class="varient-list">
            <ul>
              <li>
                {{ p.addonName }}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="!isReview" class="varient-edit" (click)="scroll(aa)">
          <a
            href="javascript: void(0)"
            (click)="editPackage(p, pindex)"
            class="btn t-grey-btn-outline edit-btn mr-1"
            >EDIT</a
          >
        </div>
        <hr />
      </div>
      <div class="varient-product-table custom-table">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <ng-container *ngFor="let c of p.columns">
                  <th scope="col" *ngIf="c.id !== 8">
                    {{ c.name }}
                  </th>
                </ng-container>
                <th scope="col" *ngIf="!isReview">
                  <a
                    href="javascript: void(0)"
                    appOpenModal
                    [config]="getPackageModalConfig(null, p.id)"
                    (onModalClose)="createRow($event, p.id)"
                    class="add-remove-btn"
                  >
                    <img src="assets/images/plus-grey.svg" alt="plus" />
                  </a>
                </th>
                <th scope="col" *ngIf="false">
                  <a
                    href="javascript: void(0)"
                    (click)="tableSetting(p.id)"
                    data-target="#variant-list"
                    data-toggle="modal"
                    class="action-btn"
                  >
                    <img
                      src="assets/images/icon-grey-more-option-h.svg"
                      alt="more-option"
                    />
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <!--Package-->
              <tr *ngIf="p.optionValues?.length > 0 && selectedVariant == 2">
                <td class="addon-item" colspan="8">
                  <strong class="text-uppercase">{{ p?.name }}</strong>
                </td>
              </tr>
              <tr *ngFor="let o of p.optionValues; let index = index">
                <td *ngFor="let c of p.columns">
                  <div class="thumb-flex" *ngIf="c.id == 1">
                    <div class="thumb-img">
                      <img
                        [src]="
                          o?.img_url
                            ? o.img_url
                            : 'assets/images/small-thumb.png'
                        "
                        alt="thumb-img"
                        appOpenModal
                        [config]="
                          getAssignVariantImageModalData(
                            o.id,
                            2,
                            pindex,
                            o?.img_url
                          )
                        "
                        (onModalClose)="getPackageAssignedImg($event)"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    [disabled]="isReview"
                    *ngIf="c.id == 2"
                    (input)="changePackageOptionValue()"
                    class="form-control"
                    [(ngModel)]="o.name"
                    style="width: 140px"
                    [readOnly]="true"
                  />
                  <input
                    *ngIf="c.id == 3"
                    type="text"
                    [disabled]="isReview"
                    class="form-control"
                    [(ngModel)]="o.sku"
                    (input)="changePackageOptionValue()"
                    style="width: 100px"
                    [readOnly]="true"
                  />
                  <div class="price-field-control" *ngIf="c.id == 4">
                    <span class="unit">AED</span>
                    <input
                      type="text"
                      [disabled]="isReview"
                      class="form-control"
                      (input)="changePackageOptionValue()"
                      [(ngModel)]="o.price"
                      gfdNumbersOnly
                      [readOnly]="true"
                    />
                  </div>
                  <div class="price-field-control" *ngIf="c.id == 5">
                    <span class="unit">AED</span>
                    <input
                      type="text"
                      [disabled]="isReview"
                      gfdNumbersOnly
                      class="form-control"
                      [(ngModel)]="o.sale_price"
                      (input)="changePackageOptionValue()"
                      style="width: 100px"
                      [readOnly]="true"
                    />
                  </div>
                  <input
                    *ngIf="c.id == 6"
                    appNumbersOnly
                    type="text"
                    class="form-control"
                    (input)="changeAddonOptionValue()"
                    [(ngModel)]="taxPercentage"
                    [ngModelOptions]="{ standalone: true }"
                    style="width: 80px"
                    [readOnly]="true"
                  />
                  <input
                    appNumbersOnly
                    *ngIf="c.id == 7"
                    type="text"
                    class="form-control"
                    [value]="calculateTotalAmount(o)"
                    style="width: 80px"
                    [readOnly]="true"
                  />
                  <input
                    type="text"
                    *ngIf="c.id == 8 && false"
                    [disabled]="isReview"
                    [readOnly]="true"
                    gfdNumbersOnly
                    class="form-control"
                    [(ngModel)]="o.available_stock"
                    (input)="changePackageOptionValue()"
                  />
                </td>
                <td>
                  <a
                    *ngIf="!isReview"
                    href="javascript: void(0)"
                    (click)="removePackageOption(index, pindex)"
                    class="add-remove-btn"
                  >
                    <img src="assets/images/minus-grey.svg" alt="plus" />
                  </a>
                </td>
                <td>
                  <a
                    href="javascript: void(0)"
                    appOpenModal
                    [config]="getPackageModalConfig(o, p)"
                    (onModalClose)="getPackageOptionValuesData($event)"
                    class="btn t-grey-btn-outline edit-btn"
                    >{{ isReview ? 'VIEW' : 'EDIT' }}</a
                  >
                </td>
              </tr>
              <!--Addon-->
              <tr
                *ngIf="
                  p.optionValues?.length > 0 &&
                  (selectedVariant == 2 || p?.addonName)
                "
              >
                <td class="addon-item" colspan="8">
                  <strong class="text-uppercase"
                    >ADDON
                    <span *ngIf="p.addonName">
                      - {{ p.addonName }}</span
                    ></strong
                  >
                </td>
                <th class="sticky">
                  <a
                    *ngIf="!isReview"
                    href="javascript: void(0)"
                    appOpenModal
                    [config]="getPackageModalConfig(null, p)"
                    (onModalClose)="createAddonRow($event, p.id)"
                    class="add-remove-btn"
                  >
                    <img src="assets/images/plus-grey.svg" alt="plus" />
                  </a>
                </th>
              </tr>
              <tr *ngFor="let o of p.addonOptionValues; let index = index">
                <td *ngFor="let c of p.columns">
                  <div class="thumb-flex" *ngIf="c.id == 1">
                    <div class="thumb-img">
                      <img
                        [src]="
                          o?.img_url
                            ? o.img_url
                            : 'assets/images/small-thumb.png'
                        "
                        alt="thumb-img"
                        appOpenModal
                        [config]="
                          getAssignVariantImageModalData(
                            o.id,
                            3,
                            pindex,
                            o?.img_url
                          )
                        "
                        (onModalClose)="getPackageAssignedImg($event)"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    [disabled]="isReview"
                    [readOnly]="true"
                    *ngIf="c.id == 2"
                    (input)="changeAddonOptionValue()"
                    class="form-control"
                    [(ngModel)]="o.name"
                  />
                  <input
                    *ngIf="c.id == 3"
                    type="text"
                    [disabled]="isReview"
                    class="form-control"
                    [readOnly]="true"
                    [(ngModel)]="o.sku"
                    (input)="changeAddonOptionValue()"
                  />
                  <div class="price-field-control" *ngIf="c.id == 4">
                    <span class="unit">AED</span>
                    <input
                      type="text"
                      [disabled]="isReview"
                      class="form-control"
                      (input)="changeAddonOptionValue()"
                      [(ngModel)]="o.price"
                      [readOnly]="true"
                      gfdNumbersOnly
                    />
                  </div>
                  <div class="price-field-control" *ngIf="c.id == 5">
                    <span class="unit">AED</span>
                    <input
                      type="text"
                      [disabled]="isReview"
                      gfdNumbersOnly
                      class="form-control"
                      [readOnly]="true"
                      [(ngModel)]="o.sale_price"
                      (input)="changeAddonOptionValue()"
                      style="width: 100px"
                    />
                  </div>
                  <input
                    *ngIf="c.id == 6"
                    appNumbersOnly
                    type="text"
                    class="form-control"
                    (input)="changeAddonOptionValue()"
                    [(ngModel)]="taxPercentage"
                    [ngModelOptions]="{ standalone: true }"
                    style="width: 80px"
                    [readOnly]="true"
                  />
                  <input
                    appNumbersOnly
                    *ngIf="c.id == 7"
                    type="text"
                    class="form-control"
                    [value]="calculateTotalAmount(o)"
                    style="width: 80px"
                    [readOnly]="true"
                  />
                  <input
                    type="text"
                    *ngIf="c.id == 8 && false"
                    [readOnly]="true"
                    [disabled]="isReview"
                    gfdNumbersOnly
                    [allowDecimals]="false"
                    class="form-control"
                    [(ngModel)]="o.available_stock"
                    (input)="changeAddonOptionValue()"
                  />
                </td>
                <td *ngIf="!isReview">
                  <a
                    href="javascript: void(0)"
                    (click)="removeAddonOption(index, pindex)"
                    class="add-remove-btn"
                  >
                    <img src="assets/images/minus-grey.svg" alt="plus" />
                  </a>
                </td>
                <td>
                  <a
                    href="javascript: void(0)"
                    appOpenModal
                    [config]="getPackageModalConfig(o, p)"
                    (onModalClose)="getPackageOptionValuesData($event)"
                    class="btn t-grey-btn-outline edit-btn"
                    >{{ isReview ? 'VIEW' : 'EDIT' }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Packages variant setting modal-->
<div
  class="modal fade custom-modal add-variants-modal"
  id="variant-list"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-add-package-custom-setting-modal
        [packageID]="packageSettingID"
        (showColumns)="getShowCloumn($event)"
      >
      </app-add-package-custom-setting-modal>
    </div>
  </div>
</div>

<!--Assign variant image-->
<!-- <div
  class="modal fade custom-modal add-custom-info-modal"
  id="#assign-img"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <app-assign-variant-image-modal
    [isReview]="isReview"
    [selectAssignedImgUrl]="selectAssignedImgUrl"
    [assignImgPackageIndex]="assignImgPackageIndex"
    [optionValueID]="optionValueID"
    (assignedImg)="getPackageAssignedImg($event)"
    [existingImages]="oldImages"
    [isVariantImg]="isVariantImg"
    [isAddonImg]="isAddonImg"
    [selectedProductImages]="newImages"
  ></app-assign-variant-image-modal>
</div> -->
