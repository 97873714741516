import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';

@Injectable()
export class EnsureBaseUrlInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.getApiBaseUrl(req.url);
    // clone request
    const secureReq = req.clone({ url });

    // send the cloned
    return next.handle(secureReq);
  }

  /**
   * Get api base url
   * @param url
   * @returns
   */
  private getApiBaseUrl(url: string) {
    return url.startsWith('http') ||
      url.startsWith('https') ||
      url.includes('assets/')
      ? url
      : `${environment.baseApiUrl}${url}`;
  }
}
