import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GeneralService, StorageService } from '@app/core/services';
import { SupportService } from '@app/core/services/support.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';
import { IApiResponse } from '@app/shared/model/api-response.model';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-ticket-upload-tax-invoice-modal',
  templateUrl: './ticket-upload-tax-invoice-modal.component.html',
})
export class TicketUploadTaxInvoiceModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('invoiceUploadInput') invoiceUploadInput!: ElementRef;
  @ViewChild('closeModal') closeModal: any;
  invoiceID: any;
  invoiceType: any;

  invoiceImageUrl: any;
  invoiceFormData: any;
  invoiceToUpload: any;
  invoieFileName: any = '';
  invoiceTicketDetails: any;
  finalTotal: any;

  constructor(
    private generalService: GeneralService,
    private supportService: SupportService,
    private storageService: StorageService,
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      invoiceID: any;
      invoiceType: any;
    }
  ) {
    super();
    this.invoiceType = data?.invoiceType ? data.invoiceType : '';
    this.invoiceID = data?.invoiceID ? data.invoiceID : 0;
  }

  ngOnInit(): void {
    if (this.invoiceID) {
      this.getInvoiceTicketDetails();
    }
  }

  handleFileInput(event: any): void {
    const file: File = event.target.files[0];
    this.invoieFileName = file.name;
    if (file) {
      if (file.type == 'application/pdf') {
        this.invoiceFormData = new FormData();
        this.invoiceFormData.append('request_invoice', file);
        this.invoiceToUpload = event.target.files[0];
        if (this.invoiceToUpload) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.invoiceImageUrl = event.target.result;
            this.invoiceUploadInput.nativeElement.value = '';
          };
          reader.readAsDataURL(this.invoiceToUpload);
        }
      } else {
        this.invoiceUploadInput.nativeElement.value = '';
        this.generalService.displayError('Please select only pdf file');
      }
    } else {
      this.invoiceUploadInput.nativeElement.value = '';
      this.generalService.displayError('Please select file');
    }
  }

  uploadInvoiceFile(invoiceID: any): void {
    this.invoiceFormData.append('request_invoice_id', invoiceID);
    this.invoiceFormData.append(
      'seller_id',
      this.storageService.getCookie('userID')
    );
    this.supportService
      .uploadInvoiceFile(this.invoiceFormData)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: IApiResponse) => {
          if (res.success) {
            this.generalService.displaySuccess(res?.message);
            this.removeTicketImage();
            this.closeMdl({ update: true });
          } else {
            this.generalService.displayError(res.message);
          }
        },
        error: (error) => {
          this.generalService.displayError(error?.error?.message);
        },
      });
  }

  removeTicketImage(): void {
    this.invoiceImageUrl = '';
    this.invoiceToUpload = '';
    this.invoiceFormData = new FormData();
    this.invoieFileName = '';
  }

  closeMdl(data?: any): void {
    this.dialogRef.close(data);
  }

  sendInvoice(): void {
    if (this.invoiceToUpload) {
      this.uploadInvoiceFile(this.invoiceID);
    } else {
      this.generalService.displayError('Please upload invoice');
    }
  }

  reUpload(): void {
    this.invoiceUploadInput.nativeElement.value = '';
    this.generalService.displayError('Please select file');
  }

  getInvoiceTicketDetails(): void {
    const payload = {
      invoiceID: this.invoiceID,
    };
    this.supportService
      .getTaxInvoiceDetails(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res) {
            this.invoiceTicketDetails = res?.data;
            this.finalTotal =
              this.invoiceType == 1
                ? this.invoiceTicketDetails?.total
                : this.invoiceTicketDetails?.total -
                  this.invoiceTicketDetails?.tax;
          }
        },
        error: (error) => {
          this.generalService.displayError(error?.error?.message);
        },
      });
  }
}
