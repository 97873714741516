<div
  class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title"></div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        #cancelModal
        (click)="close()"
      >
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="commit-modal-content">
        <div [innerHTML]="message"></div>
      </div>
    </div>
  </div>
</div>
