<div class="modal-body">
  <div class="modal-title">
    <h4>Change Email</h4>
  </div>
  <form name="emailForm" [formGroup]="emailForm">
    <div class="change-email-content">
      <div class="form-group">
        <label for=""
          >Enter new email address<span class="required">*</span>
        </label>
        <input type="text" class="form-control" formControlName="email" />
        <div
          *ngIf="f['email'].invalid && f['email'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['email'].errors?.['required']"
            class="form-text text-danger"
          >
            Email is required.
          </div>
          <div
            *ngIf="emailForm.controls['email'].hasError('pattern')"
            class="form-text text-danger"
          >
            Valid Email is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="password-group">
          <label for="">Enter password<span class="required">*</span> </label>
          <input
            [type]="showPwd ? 'text' : 'password'"
            class="form-control"
            id="current1-pwd"
            formControlName="password"
            (input)="validatePwd()"
          />
          <span class="show-pwd" id="show-hide-pwd" (click)="showPwd = !showPwd"
            ><img
              [src]="
                showPwd
                  ? 'assets/images/show-hide-icon.svg'
                  : 'assets/images/show-eye-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
        <div
          *ngIf="f['password'].invalid && f['password'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['password'].errors && f['password'].errors['required']"
            class="form-text text-danger"
          >
            Password is required.
          </div>
        </div>

        <div
          *ngIf="emailForm.value.rePassword && !passMatch"
          class="form-text text-danger"
        >
          Password does not match with re-password.
        </div>
      </div>
      <div class="password-checklist">
        <h6>Password must:</h6>
        <ul>
          <li
            [ngClass]="{
              checked: passStrength?.passStrength?.contains.includes('number')
            }"
          >
            Contain at least one numeric character
          </li>
          <li
            [ngClass]="{
              checked:
                passStrength?.passStrength?.contains.includes('uppercase')
            }"
          >
            Contain at least one uppercase letter
          </li>
          <li
            [ngClass]="{
              checked:
                passStrength?.passStrength?.contains.includes('lowercase')
            }"
          >
            Contain at least one lowercase letter
          </li>
          <li [ngClass]="{ checked: passStrength?.isContainSpace }">
            Not contain any spaces
          </li>
        </ul>
      </div>
      <div class="form-group">
        <div class="password-group">
          <label for=""
            >Re-enter password<span class="required">*</span>
          </label>
          <input
            [type]="showRetypePwd ? 'text' : 'password'"
            class="form-control"
            id="re1-pwd"
            formControlName="rePassword"
            (input)="comparePassword()"
          />
          <span
            class="show-pwd"
            id="show-hide-pwd"
            (click)="showRetypePwd = !showRetypePwd"
            ><img
              [src]="
                showRetypePwd
                  ? 'assets/images/show-hide-icon.svg'
                  : 'assets/images/show-eye-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
        <div
          *ngIf="f['rePassword'].invalid && f['rePassword'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['rePassword'].errors && f['rePassword'].errors['required']"
            class="form-text text-danger"
          >
            Re-Password is required
          </div>
        </div>
        <div
          *ngIf="emailForm.value.rePassword && !passMatch"
          class="form-text text-danger"
        >
          Re-Password does not match with password.
        </div>
      </div>
    </div>
  </form>

  <div class="filter-actions-bottom card-box-action">
    <ul>
      <li>
        <a href="javascript: void(0)" data-dismiss="modal" #closeMdl>
          Cancel
        </a>
      </li>
      <li class="last-btn">
        <a href="javascript: void(0)" (click)="save()" class="btn t-blue-btn">
          Submit
        </a>
      </li>
    </ul>
  </div>
</div>
