import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GeneralService, StorageService } from '@app/core/services';
import { WalletService } from '@app/core/services/wallet.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-bank-details-modal',
  templateUrl: './bank-details-modal.component.html',
})
export class BankDetailsModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnChanges
{
  @Input() bankID: any;
  @Input() mobile: any;
  @ViewChild('closeModal') closeModal: any;
  @Output() openOTPVerificationModal = new EventEmitter();

  constructor(
    private walletService: WalletService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  bankAccountDetails: any;

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.bankID) {
      this.getBankDetails();
    }
  }

  getBankDetails(): void {
    this.walletService
      .getBankAccountDetails(this.bankID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          if (response) {
            this.bankAccountDetails = response?.data;
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  removeCard(): void {
    this.closeModal?.nativeElement?.click();
    this.openOTPVerificationModal?.emit();
    this.sendOTP();
  }

  sendOTP(): void {
    const vendorID = this.storageService.getCookie('userID');
    if (!this.mobile) {
      this.generalService.displayWarning('Please enter valid mobile number');
      return;
    } else {
      this.walletService
        .sendOTPForDeleteAccountVerification({
          mobile_number: this.mobile,
          seller_id: vendorID,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }
}
