import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VendorService,
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { interval, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnDestroy
{
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;
  // Variables
  showPwd = false;
  showRetypePwd = false;
  passMatch = false;
  emailParm: any = '';
  passStrength: any;
  isPwdValid = false;
  otp: any = '';
  counter = 0;
  expireTime: any;

  resendClicked = false;

  resetPassForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [ValidationService.required]),
    retypePassword: new UntypedFormControl('', [ValidationService.required]),
    digitOne: new UntypedFormControl('', [ValidationService.required]),
    digitTwo: new UntypedFormControl('', [ValidationService.required]),
    digitThree: new UntypedFormControl('', [ValidationService.required]),
    digitFour: new UntypedFormControl('', [ValidationService.required]),
  });

  otpArray = ['digitOne', 'digitTwo', 'digitThree', 'digitFour'];

  // Functions
  constructor(
    private vendorService: VendorService,
    public generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.expireTime = this.route.snapshot.data.detail.expiryTime;
    this.counter = 0;
    this.startTimer();
    this.generalService.isShowFooter = false;
    this.generalService.isShowHeader = false;
    const pageUrl = this.router.url;
    if (pageUrl) {
      const urlArr = pageUrl?.split('/');
      if (urlArr?.length > 0) {
        this.emailParm = urlArr[urlArr?.length - 1];
      }
      if (this.emailParm) {
        const userID = this.storageService.getCookie('userID');
        if (userID) {
          this.generalService.logout();
        }
        this.router.navigate(['/reset/' + this.emailParm]);
        this.emailParm = this.emailParm;
      }
      if (!this.emailParm) {
        this.router.navigate(['/login/forget']);
      }
    }
  }

  get f() {
    return this.resetPassForm.controls;
  }

  comparePassword() {
    const { password, retypePassword } = this.resetPassForm.getRawValue();
    if (password == retypePassword) this.passMatch = true;
    else this.passMatch = false;
  }

  validatePwd(): void {
    const { password } = this.resetPassForm.getRawValue();
    this.passStrength = this.generalService.validatePassword(password);
    const { isContainSpace, contains } = this.passStrength?.passStrength || {};
    if (
      !isContainSpace &&
      contains?.includes('lowercase') &&
      contains?.includes('number') &&
      contains?.includes('uppercase')
    ) {
      this.isPwdValid = true;
    } else {
      this.isPwdValid = false;
    }
    this.comparePassword();
  }

  setNewPwd(): void {
    if (this.resetPassForm.invalid) {
      this.resetPassForm.markAllAsTouched();
      return;
    } else {
      const { retypePassword } = this.resetPassForm.getRawValue();
      this.otp =
        this.resetPassForm.value.digitOne +
        '' +
        this.resetPassForm.value.digitTwo +
        '' +
        this.resetPassForm.value.digitThree +
        '' +
        this.resetPassForm.value.digitFour;

      if (this.passMatch && this.isPwdValid) {
        this.vendorService
          .resetpassword({
            id: this.emailParm,
            password: retypePassword,
            otp: this.otp,
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response) => {
              if (response.success) {
                const userID = this.storageService.getCookie('userID');
                if (!userID) {
                  this.router.navigate(['/login/change-password-confirmation']);
                }

                this.generalService.displaySuccess(response?.message);
              } else {
                this.generalService.displayError(response?.message);
              }
            },
            (error) => {
              this.generalService.displayError(error?.error?.message);
            }
          );
      } else {
        this.generalService.displayWarning('Please enter vaild password.');
        return;
      }
    }
  }

  override ngOnDestroy() {
    this.generalService.isShowFooter = true;
    this.generalService.isShowHeader = true;
  }

  startTimer() {
    const apiTimer = new Date(this.expireTime).getTime();
    const currentTimer = new Date().getTime();
    this.counter = Math.floor((apiTimer - currentTimer) / 1000);
    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          this.destroyed$.next();
          this.destroyed$.complete();
          this.resendClicked = true;
        }
      });
  }

  resendOTP(): void {
    if (this.resendClicked) {
      this.expireTime = '';
      this.counter = 0;
      this.vendorService
        .reSendOTP(this.emailParm)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response.success) {
              this.generalService.displaySuccess(response?.message);
              this.expireTime = response?.data?.expiryTime;
              this.resendClicked = false;
              this.startTimer();
            } else {
              this.resendClicked = true;
              this.generalService.displayError(response?.message);
            }
          },
          (error) => {
            this.resendClicked = true;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  handleInput(ele: number): void {
    if (this.resetPassForm.controls[this.otpArray[ele - 1]].valid) {
      setTimeout(() => {
        this.otpInputs.get(ele)?.nativeElement.focus();
      });
    }
  }
}
