import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private httpClient: HttpClient) {}

  addLog(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/logs`,
      payload
    );
  }

  getLogList(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/logs?${payload}`
    );
  }

  getLogDetails(id: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/logs/${id}`
    );
  }
}
