import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
})
export class TooltipComponent implements OnInit {
  @Input() isInfoTooltip: any;
  @Input() fieldData: any;
  @Input() fieldInfo: any;

  isClass = true;

  constructor() {}

  ngOnInit(): void {
    this.getTooltipData();
    this.getTooltipInfo();
  }

  getTooltipData() {
    let template;
    if (this.fieldData?.review_status == 2) {
      template =
        '<div class=tooltip-inner><h6>' +
        this.fieldData?.field +
        '</h6><p>' +
        (this.fieldData?.rejection_reason
          ? this.fieldData?.rejection_reason
          : 'No reason binded') +
        '</p></div>';
    } else if (this.fieldData?.review_status == 1) {
      template =
        '<div class=tooltip-inner><h6>' +
        this.fieldData?.field +
        ' approved </h6></div>';
    } else {
      template =
        '<div class=tooltip-inner><h6>' +
        this.fieldData?.field +
        '</h6><span class="t-badge yellow2">Update send to Admin</span></div>';
    }
    return template;
  }

  getTooltipInfo() {
    // const template = '<div class=tooltip-inner><h6>' + this.fieldInfo + '</h6><p>lorem ipsum dolor in it!</p></div>'
    const template = '<div class=tooltip-inner><p>' + this.fieldInfo + '</p>';
    return template;
  }
}
