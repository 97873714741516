import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configDate',
})
export class ConfigDatePipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    let changedValue: string | null = '';
    if (value) {
      switch (args[0]) {
        case 'MMM DD,YYYY':
          changedValue = new DatePipe('en-US').transform(value, 'MMM dd, YYYY');
          break;
        case 'DD MMM, YYYY':
          changedValue = new DatePipe('en-US').transform(value, 'dd MMM, YYYY');
          break;
        case 'DD/MM/YY':
          changedValue = new DatePipe('en-US').transform(value, 'dd/MM/YY');
          break;
        default:
          changedValue = new DatePipe('en-US').transform(value, 'MMM dd, YYYY');
      }
    }
    return changedValue;
  }
}
