import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  APPLICATION_STATUS,
  ICheckListItem,
} from '@app/shared/model/vendor.model';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
})
export class CheckListComponent implements OnInit {
  @Input() set isSubmit(val: boolean) {
    this.isSubmitted = val;
  }

  @Output() statusChanged: EventEmitter<ICheckListItem[]> = new EventEmitter();

  @Input() set items(val: ICheckListItem[]) {
    val.forEach((ele) => {
      if (['STORE_NAME', 'LICENSE_NO', 'LOGO'].includes(ele.logType)) {
        ele['logo'] = 'assets/images/cloud-upload-icon.svg';
      }

      if (ele.logType == 'LOGO') {
        ele['logo'] = 'assets/images/cloud-upload-icon.svg';
      }
      if (ele.logType == 'COMPANY_NAME') {
        ele['logo'] = 'assets/images/cloud-upload-icon.svg';
      }
      if (ele.logType == 'TRN') {
        ele['logo'] = 'assets/images/icon-share.svg';
      }
      if (ele.logType == 'TRADE_LICENSE') {
        ele['logo'] = 'assets/images/icon-share.svg';
      }
      if (ele.logType == 'VAT_CERTIFICATE') {
        ele['logo'] = 'assets/images/cloud-upload-icon.svg';
      }
    });

    this.checkList = [...val];
  }
  applicationStatus = APPLICATION_STATUS;
  checkList: ICheckListItem[] = [];

  isSubmitted = false;

  ngOnInit(): void {
    this.statusChanged.emit(this.checkList);
  }

  changeStatus(ele: ICheckListItem, status: number, index: number) {
    this.checkList[index] = {
      ...ele,
      status: status,
      reason: this.applicationStatus.APPROVE === status ? '' : ele.reason,
    };
    this.statusChanged.emit(this.checkList);
  }
}
