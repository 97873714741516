<div class="modal-body">
  <div class="request-payout-form" [formGroup]="payoutForm">
    <h4 class="title">Request Payout</h4>
    <div class="beneficiary-selection">
      <label for="">Beneficiary</label>
      <ng-select2
        *ngIf="beneficiaryList.length > 0"
        formControlName="beneficiary"
        class="select2-control"
        name=""
        id="beneficiaryID"
        [data]="beneficiaryList"
        [placeholder]="'Select'"
      >
      </ng-select2>
      <div
        *ngIf="f['beneficiary'].invalid && f['beneficiary'].touched"
        class="cross-validation-error-message mt-2"
      >
        <div
          *ngIf="f['beneficiary'].errors && f['beneficiary'].errors['required']"
          class="required-note text-danger"
        >
          Beneficiary is required.
        </div>
      </div>
    </div>
    <div class="beneficiary-account-details">
      <h5 class="name">{{ payoutForm.value?.beneficiary }}</h5>
      <label>Account Number</label>
      <div class="account-info">
        <ul>
          <li>
            <div class="left-label">Account Holder Name</div>
            <div class="right-label">{{ bankDetails?.name }}</div>
          </li>
          <li>
            <div class="left-label">Account Number</div>
            <div class="right-label">{{ bankDetails?.account_number }}</div>
          </li>
          <li>
            <div class="left-label">IBAN</div>
            <div class="right-label">{{ bankDetails?.iban }}</div>
          </li>
          <li>
            <div class="left-label">Swift Code</div>
            <div class="right-label">{{ bankDetails?.swift_code }}</div>
          </li>
          <li>
            <div class="left-label">Bank Name</div>
            <div class="right-label">{{ bankDetails?.bankdetail?.name }}</div>
          </li>
        </ul>
      </div>
      <label>Enter Amount</label>
      <div class="price-field-control">
        <span class="unit">AED</span>
        <input
          type="text"
          class="form-control"
          formControlName="amount"
          placeholder="1011.15"
          (keypress)="generalService.numberOnly($event)"
        />
      </div>
      <div
        *ngIf="f['amount'].invalid && f['amount'].touched"
        class="cross-validation-error-message"
      >
        <div
          *ngIf="f['amount'].errors && f['amount'].errors['required']"
          class="required-note text-danger"
        >
          Amount is required.
        </div>
      </div>
    </div>
    <div class="bottom-btnrow">
      <button
        type="button"
        class="btn t-grey-btn"
        #closeModal
        data-dismiss="modal"
        (click)="reset()"
      >
        Cancel
      </button>
      <button type="button" class="btn t-primary-btn" (click)="requestPayout()">
        Request Payout
      </button>
    </div>
  </div>
</div>
