<span
  *ngIf="!isInfoTooltip && fieldData"
  class="tt-icon"
  [tooltipClass]="
    fieldData?.review_status == 2
      ? 'custom-ng-tooltip red-tt'
      : fieldData?.review_status == 1
      ? 'custom-ng-tooltip green-tt'
      : fieldData?.review_status == 0
      ? 'custom-ng-tooltip yellow-tt'
      : ''
  "
  [tooltip]="getTooltipData()"
>
  <img
    *ngIf="fieldData"
    src="assets/images/{{
      fieldData?.review_status == 2
        ? 'info-tt-red.svg'
        : fieldData?.review_status == 1
        ? 'info-tt-green.svg'
        : fieldData?.review_status == 0
        ? 'info-tt-yellow.svg'
        : ''
    }}"
    alt="icon-tt"
  />
</span>

<a
  *ngIf="isInfoTooltip"
  href="javascript: void(0)"
  class="info-tooltip"
  [tooltipClass]="'custom-ng-tooltip'"
  [tooltip]="getTooltipInfo()"
>
  <img src="assets/images/info-tooltip.svg" alt="info-tooltip" />
</a>
