import { Component, OnInit } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { GeneralService } from './core/services/general.service';
import { StorageService } from './core/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private idle: Idle,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    this.userIdleHandle();
  }

  ngOnInit(): void {
    if (
      this.storageService.getCookie('AccessToken') &&
      !this.idle.isRunning()
    ) {
      this.idle.watch();
    }

    const token = this.storageService.getCookieExpiration('AccessToken');
    if (token) {
      this.storageService.setCookieExpiration('AccessToken', token, 1);
    }
  }

  userIdleHandle(): void {
    this.idle.setIdle(2 * 3600);
    this.idle.setTimeout(0);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.generalService.logout();
      this.generalService.displayWarning(
        'Session Expired',
        'Please login again'
      );
    });
  }
}
