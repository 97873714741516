<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">New Ticket</h4>
    <button
      #closeMdl
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="add-new-ticket-block" [formGroup]="ticketData">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Subject"
          formControlName="subject"
        />
        <div
          *ngIf="f['subject'].invalid && f['subject'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['subject'].errors && f['subject'].errors['required']"
            class="required-note text-danger"
          >
            Subject is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <ng-select2
          *ngIf="allOrderList.length > 0"
          class="select2-control"
          name="order"
          id="allOrderListID"
          [data]="allOrderList"
          (valueChanged)="getItemReferenceList($event)"
          [placeholder]="'Select Order / Reference Number'"
          formControlName="order"
        >
        </ng-select2>
      </div>
      <div class="form-group">
        <ng-select2
          *ngIf="allItemList.length > 0"
          class="select2-control"
          name="item"
          id="allItemListID"
          [data]="allItemList"
          (valueChanged)="getSelectedItem($event)"
          [placeholder]="'Select Item'"
          formControlName="item"
        >
        </ng-select2>
      </div>
      <div class="form-group">
        <angular-editor formControlName="description" [config]="config">
        </angular-editor>
        <div
          *ngIf="f['description'].invalid && f['description'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="
              f['description'].errors && f['description'].errors['required']
            "
            class="required-note text-danger"
          >
            Description is required.
          </div>
        </div>
      </div>
      <div class="bottom-btnrow">
        <button type="button" class="btn t-primary-btn" (click)="save()">
          <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
        </button>
      </div>
    </div>
  </div>
</div>
