import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tooltip-modal',
  templateUrl: './info-tooltip-modal.component.html',
})
export class InfoTooltipModalComponent implements OnInit {
  message: string;
  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      message: string;
    }
  ) {
    if (data?.message) {
      this.message = data.message;
    }
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef?.close();
  }
}
