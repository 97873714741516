import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { GeneralService } from '../../../core/services/general.service';
import { StorageService } from '../../../core/services/storage.service';
import { VendorService } from '../../../core/services/vendor.service';
import { catchError, interval, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('notificationButton') notificationButton!: ElementRef;
  @ViewChild('notificationDiv') notificationDiv!: ElementRef;

  isNotificationModalOpen = false;
  // orderNotificationsLists: any[] = [];
  allNotiList = [];
  requestNotiList = []
  invoiceNotifications: any[] = [];
  invoiceNotificationsTodos: any[] = [];
  isNewNotificationGet = false;
  currentTab = 'all';
  vendorID = this.storageService.getCookie('userID');
  totalInvCount: any = 0;
  totalNotiCount: any = 0;

  private pollingSubscription!: Subscription;

  constructor(
    private vendorService: VendorService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    // this.socketInit();
    this.getNewNotification();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.notificationButton?.nativeElement &&
        e.target !== this.notificationDiv?.nativeElement &&
        !this.notificationDiv.nativeElement.contains(e.target) &&
        !this.notificationButton.nativeElement.contains(e.target)
      ) {
        this.isNotificationModalOpen = false;
        document
          .getElementsByTagName('nav')[0]
          ?.classList?.remove('body-layer');
      }
    });
  }

  // socketInit(): void {
  //   if (this.vendorID) {
  //     this.vendorService.socketConnection(this.vendorID);
  //   }
  // }

  // getInvoiceNotifications(): void {
  //   return;
  //   this.vendorService.getInvoiceNotifications(this.vendorID).subscribe({
  //     next: (res) => {
  //       this.invoiceNotifications = res?.data?.done;
  //       this.invoiceNotificationsTodos = res?.data?.todo;
  //       this.totalInvCount = res?.total_records;
  //     },
  //     error: (err) => {
  //       this.generalService.displayError(err.error.message);
  //     },
  //   });
  // }

  getNotifications(isRequest = false): Observable<any> {
    const payload = {
      isAll: false,
      isRequest: isRequest,
    };
    
    return this.vendorService.getHeaderNotifications(this.vendorID, this.generalService.createParamsURL(payload)).pipe(
      tap((res) => {
        if ((!isEqual(this.allNotiList, res.data))) {
          this.allNotiList = res.data;
          this.totalNotiCount = res?.all_count;
          this.isNewNotificationGet = this.isNotificationModalOpen ? false: true;
        }
      }),
      catchError((err) => {
        this.generalService.displayError(err.error.message);
        return of(null); // Return a default observable to handle errors
      })
    );
  }
  

  getInitialNotifications(isRequest = false): void {
    const payload = {
      isAll: false,
      isRequest: isRequest,
    };
    this.vendorService.getHeaderNotifications(this.vendorID,this.generalService.createParamsURL(payload)).subscribe({next: (res) => {
      if (payload.isRequest) {
        this.requestNotiList = res.data;
        this.currentTab = 'request';
        this.totalInvCount = res?.all_count;
      } 
      else {
        this.allNotiList = res.data;
        this.totalNotiCount = res?.all_count;
        this.currentTab = 'all';
      }
    },
    error: (err) => {
      this.generalService.displayError(err.error.message);
    },
  });
  }

  getNewNotification(): void {
    this.pollingSubscription = interval(5000) // Poll every 5 seconds
    .pipe(
      switchMap(() => {
        return this.getNotifications(); 
      })
    )
    .subscribe({
      next: () => {
        console.log('Polled notifications successfully');
      },
      error: (err) => {
        this.generalService.displayError(err.error.message);
        console.error('Error fetching notifications:', err);
      }
    });



    // this.getInvoiceNotifications();
    this.getInitialNotifications(true);
    this.getInitialNotifications();
    // this.vendorService
    //   .listenEvent('vendorInvoiceNotification')
    //   .subscribe((data: any) => {
    //     if (data && data?._id) {
    //       this.getInvoiceNotifications();
    //       this.isNewNotificationGet = this.isNotificationModalOpen
    //         ? false
    //         : true;
    //     }
    //   });
  }

  openModal(): void {
    this.isNewNotificationGet = false;
    this.isNotificationModalOpen = !this.isNotificationModalOpen;
    if (this.isNotificationModalOpen) {
      document.getElementsByTagName('nav')[0]?.classList?.add('body-layer');
    } else {
      document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
    }
  }

  sendInvoice(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  ngOnDestroy(): void {
    // this.vendorService.socketDisconnect();
    this.isNewNotificationGet = false;
  }

  closeNotifyModal(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  markAsReadNotification(item: any, isall: boolean): void {
    if (item.is_read) {
      return;
    }

    const payload = {
      notification_id: item._id,
      is_all: false,
    };
    this.vendorService.markAsReadNotification(payload).subscribe({
      next: (res) => {
        // this.getInvoiceNotifications();

        this.getInitialNotifications(isall);
      },
      error: (err) => {
        this.generalService.displayError(err.error.message);
      },
    });
  }
}
