<div class="modal-header">
  <h4 class="modal-title text-uppercase">Proceed with payout</h4>
  <button
    type="button"
    #closeModal
    class="close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="payout-info-box">
    <div class="payout-info-detail">
      <div class="payout-title">
        <h6>Payout Request</h6>
        <p>{{ bankDetails?.createdAt | date : 'dd/MM/yyyy' }}</p>
      </div>
      <div class="t-badge green">Approved</div>
    </div>
    <div class="account-info">
      <!-- <a href="#" class="edit-acc-details">
                    <img src="assets/images/icon-feather-edit.svg" alt="edit">
                </a> -->
      <ul>
        <li>
          <div class="left-label">Account Holder Name</div>
          <div class="right-label">{{ bankDetails?.name }}</div>
        </li>
        <li>
          <div class="left-label">Account Number</div>
          <div class="right-label">{{ bankDetails?.account_number }}</div>
        </li>
        <li>
          <div class="left-label">IBAN</div>
          <div class="right-label">{{ bankDetails?.iban }}</div>
        </li>
        <li *ngIf="bankDetails?.swift_code">
          <div class="left-label">Swift Code</div>
          <div class="right-label">{{ bankDetails?.swift_code }}</div>
        </li>
        <li>
          <div class="left-label">Bank Name</div>
          <div class="right-label">{{ bankDetails?.bankdetail?.name }}</div>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-between">
      <div class="price-field-control">
        <span class="unit">AED</span>
        <input type="text" readonly class="form-control" [(ngModel)]="amount" />
      </div>
      <button type="button" class="btn t-primary-btn" (click)="continue()">
        <img src="assets/images/tickmark-white.svg" alt="continue" /> CONTINUE
        TO PAYOUT
      </button>
    </div>
  </div>
</div>
