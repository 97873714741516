import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  VendorService,
  GeneralService,
  StorageService,
  AccountSettingsService,
  ValidationService,
} from '@app/core/services';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-verify-email-address-modal',
  templateUrl: './verify-email-address-modal.component.html',
})
export class VerifyEmailAddressModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnChanges
{
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('closeMdl') closeMdl: any;
  @ViewChild('closeSMSMdl') closeSMSMdl: any;
  @Output() closeEmailOTPModal = new EventEmitter();
  @Output() closeSMSOTPModal = new EventEmitter();
  @Input() email: any;
  @Input() mobile: any;
  @Input() mobileJSON: any;
  @Input() isShowSMSOTP: any;
  @Input() isShowEmailOTP: any;

  otp: any = '';
  counter = 60;
  resendClicked = false;
  encodeMobile: any = '';
  otpForm = new UntypedFormGroup({
    digitOne: new UntypedFormControl('', [ValidationService.required]),
    digitTwo: new UntypedFormControl('', [ValidationService.required]),
    digitThree: new UntypedFormControl('', [ValidationService.required]),
    digitFour: new UntypedFormControl('', [ValidationService.required]),
  });
  otpArray = ['digitOne', 'digitTwo', 'digitThree', 'digitFour'];

  constructor(
    private vendorService: VendorService,
    public generalService: GeneralService,
    private storageServie: StorageService,
    private accountSettingService: AccountSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resendClicked = true;
  }

  ngOnChanges(): void {
    this.counter = 60;
    if (this.isShowSMSOTP) {
      this.sendOTP();
      this.encodeMobile = this.mobile?.replace(
        this.mobile?.substring(4, 11),
        '*******'
      );
    }
    if (this.isShowEmailOTP) {
      this.startTimer();
    }
  }

  verify(): void {
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    } else {
      this.otp =
        this.otpForm.value.digitOne +
        '' +
        this.otpForm.value.digitTwo +
        '' +
        this.otpForm.value.digitThree +
        '' +
        this.otpForm.value.digitFour;
      const payload = {
        email: this.email,
        otp: this.otp,
        mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
      };
      this.accountSettingService
        .verifyEmail(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.otp = '';
              this.otpForm.reset();
              this.isShowSMSOTP ? this.closeSMSModal() : this.closeModal();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  startTimer() {
    const otpTimer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else if (this.counter == 0) {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      } else {
        this.counter = 60;
        clearInterval(otpTimer);
        this.resendClicked = false;
      }
    }, 1000);
  }

  handleInput(ele: number): void {
    if (this.otpForm.controls[this.otpArray[ele - 1]].valid) {
      setTimeout(() => {
        this.otpInputs.get(ele)?.nativeElement.focus();
      });
    }
  }

  resendOTP(): void {
    if (this.resendClicked) {
      return;
    }
    if (!this.email && !this.isShowSMSOTP) {
      this.generalService.displayWarning('Please enter valid email');
      return;
    } else if (!this.mobile && this.isShowSMSOTP) {
      this.generalService.displayWarning('Please enter valid mobile');
      return;
    } else {
      this.resendClicked = true;
      const seller_id = this.storageServie.getCookie('userID');
      this.accountSettingService
        .editEmail({
          email: this.email,
          mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
          seller_id: seller_id,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.startTimer();
            }
          },
          (error) => {
            this.resendClicked = false;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  sendOTP(): void {
    if (!this.mobile) {
      this.generalService.displayWarning('Please enter valid mobile');
      return;
    } else {
      const seller_id = this.storageServie.getCookie('userID');
      this.accountSettingService
        .editEmail({
          email: this.email,
          mobile_number: this.isShowSMSOTP ? this.mobileJSON : '',
          seller_id: seller_id,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            if (response) {
              this.generalService.displaySuccess(response?.message);
              this.counter = 60;
              this.startTimer();
            }
          },
          (error) => {
            this.resendClicked = false;
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  reset(): void {
    this.otpForm.reset();
    this.counter = 60;
  }

  closeModal(): void {
    this.closeMdl?.nativeElement?.click();
    this.closeEmailOTPModal.emit();
  }

  closeSMSModal(): void {
    this.closeSMSMdl?.nativeElement?.click();
    this.closeSMSOTPModal.emit();
  }

  get f() {
    return this.otpForm.controls;
  }
}
