import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';
import { IApiResponse } from '@app/shared/model/api-response.model';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs';

import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler
  extends SubscriptionDisposerComponent
  implements ErrorHandler
{
  constructor(private injector: Injector) {
    super();
  }

  private get logService(): LogService {
    return this.injector.get(LogService);
  }

  handleError(error: any): void {
    const payload = {
      message: error.message,
      errorStack: error.stack,
    };
    if (!environment.production) {
      console.error(error);
    } else {
      this.logService
        .addLog(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: IApiResponse) => {
          if (res?.success) {
            console.log('error logged successfully');
          }
        });
    }
  }
}
