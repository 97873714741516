import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ValidationService } from '@app/core/services';

import { AppSettingsService } from '@app/core/services/app-setting.service';
import { IAppSettings } from '@app/shared/model/common-model';
import { GeneralService } from '@core/services/general.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-package-configuration-modal',
  templateUrl: './package-configuration-modal.component.html',
})
export class PackageConfigurationModalComponent implements OnInit {
  @Output() editPackageOptionValuesData = new EventEmitter();
  @Output() isClosed = new EventEmitter();

  selectedPackageData: any;
  packageOptionValues?: any;
  packageOptionID?: any;
  defaultPackage: any;
  isReview = false;
  taxPercentage: number;
  categoryID: string;
  removeTaxPercentage = true;
  isSubmitted = false;

  packageData = new UntypedFormGroup({
    title: new UntypedFormControl('', [ValidationService.required]),
    sale_price: new UntypedFormControl('', [ValidationService.required]),
    regular_price: new UntypedFormControl(''),
    sku: new UntypedFormControl(''),
    stock: new UntypedFormControl(1),
    description: new UntypedFormControl(''),
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
  });
  config!: AngularEditorConfig;
  appSettings: IAppSettings;
  totalAmount: number;

  constructor(
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      selectedPackageData: any;
      packageOptionValues: any;
      packageOptionID: any;
      defaultPackage: any;
      isReview: boolean;
      categoryID: string;
    },
    public generalService: GeneralService,
    public appSettingsService: AppSettingsService
  ) {
    if (data?.selectedPackageData) {
      this.selectedPackageData = data.selectedPackageData;
    }
    if (data?.packageOptionValues) {
      this.packageOptionValues = data.packageOptionValues;
    }
    if (data?.packageOptionID) {
      this.packageOptionID = data.packageOptionID;
    }
    if (data?.defaultPackage) {
      this.defaultPackage = data.defaultPackage;
    }
    if (data?.isReview) {
      this.isReview = data.isReview;
    }
    if (data?.categoryID) {
      this.categoryID = data.categoryID;
    }
  }

  get f() {
    return this.packageData.controls;
  }

  ngOnInit(): void {
    this.appSettings = this.appSettingsService.appSettings;
    this.setPackageDetails();
    if (this.isReview) {
      this.packageData.disable();
    }
    this.config = this.generalService.getEditorConfig();
    this.setTaxercentageIfAvilable(this.categoryID);
    if (!this.defaultPackage) {
      this.packageData.get('sku').setValidators([ValidationService.required]);
    } else {
      this.packageData.get('sku').clearValidators();
    }
    this.packageData.get('sku').updateValueAndValidity();
  }

  setPackageDetails() {
    if (this.defaultPackage) {
      this.packageData.patchValue({
        name: this.packageOptionValues?.title,
        title: this.selectedPackageData?.title,
        regular_price: this.selectedPackageData?.regular_price
          ? this.selectedPackageData?.regular_price
          : '',
        sku: this.selectedPackageData?.sku,
        description: this.selectedPackageData?.description,
        id: this.selectedPackageData?.id,
        sale_price: this.selectedPackageData?.sale_price,
      });
      this.calculateTotalAmount();
    } else {
      this.packageData.patchValue({
        name: this.packageOptionValues?.name,
        title: this.packageOptionValues?.name,
        regular_price: this.packageOptionValues?.price
          ? this.packageOptionValues?.price
          : '',
        sku: this.packageOptionValues?.sku,
        description: this.packageOptionValues?.description,
        id: this.packageOptionValues?.id,
        sale_price: this.packageOptionValues?.sale_price,
      });
      this.calculateTotalAmount();
    }
  }

  setTaxercentageIfAvilable(categoryId?: string) {
    this.taxPercentage = this.appSettings?.TAX_PERCENTAGE
      ? this.appSettings?.TAX_PERCENTAGE
      : 0;
    if (this.taxPercentage > 0 && categoryId !== null) {
      const taxExemptions: string[] = this.appSettings?.TAX_EXEMPTIONS;

      const targetIdToCheck: string = categoryId;

      const isIdPresent: boolean = taxExemptions.includes(targetIdToCheck);

      if (isIdPresent) {
        this.removeTaxPercentage = true;
        this.taxPercentage = 0;
      } else {
        this.removeTaxPercentage = false;
        this.taxPercentage = this.appSettings?.TAX_PERCENTAGE;
      }
    } else {
      this.taxPercentage = this.appSettings?.TAX_PERCENTAGE;
    }
  }

  save(): void {
    this.isSubmitted = true;
    if (this.packageData.invalid) {
      this.packageData.markAllAsTouched();
      return;
    } else {
      const data = this.packageData.value;
      const packageData = {
        ...data,
        regular_price: Number(this.packageData.value.regular_price),
        sale_price: Number(this.packageData.value.sale_price),
      };
      const obj = {
        packageOptionID: this.packageOptionID,
        packageOptionValues: packageData,
        packageData: this.selectedPackageData,
      };
      this.close(obj);
    }
  }

  checkValidPrice() {
    const regularPrice = Number(
      this.packageData.controls['regular_price'].value
    );
    const salePriceError = this.packageData.controls['sale_price']?.errors;
    const salePrice = Number(this.packageData.controls['sale_price'].value);
    if (regularPrice > 0 && regularPrice < salePrice) {
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
        salePriceInvalid: true,
      });
    } else {
      if (salePriceError) {
        delete salePriceError['salePriceInvalid'];
      }
      this.packageData.controls['sale_price']?.setErrors({
        ...salePriceError,
      });
      this.packageData.controls['sale_price'].updateValueAndValidity();
    }
  }

  calculateTotalAmount() {
    const salePrice = this.packageData.get('sale_price').value;
    const taxRate = this.taxPercentage;
    const totalPrice = Number(salePrice) ? Number(salePrice) : 0;
    const taxAmount = (Number(totalPrice) * Number(taxRate)) / 100;
    const totalAmount = Number(totalPrice) + Number(taxAmount);
    this.totalAmount = totalAmount > 0 ? totalAmount : 0.0;
  }

  reset(): void {
    this.packageData.reset();
    this.setPackageDetails();
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }
}
