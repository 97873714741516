import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configCurrency',
})
export class ConfigCurrencyPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    const thousandSeparator = (args[0] ? args[0] : "'") as string;
    const decimalSeparator = (args[1] ? args[1] : '"') as string;

    // code start for only separactor replace
    return value
      ? value.replace(/,/g, thousandSeparator).replace('.', decimalSeparator)
      : '';
  }
}
