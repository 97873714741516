import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { EnsureBaseUrlInterceptor } from './ensure-base-url.interceptor';
import { ErrorInterceptor } from './error.interceptor';

export function addInterceptor<T>(interceptor: T) {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: interceptor,
    multi: true,
  };
}
export const HTTP_INTERCEPTOR_PROVIDERS = [
  /**
   * ParamInterceptor will used for setting path parameters value in every api call.
   */
  addInterceptor(ErrorInterceptor),

  /**
   * EnsureBaseUrlInterceptor will used to api base urls.
   */
  addInterceptor(EnsureBaseUrlInterceptor),

  /**
   * AuthInterceptor will used for handels authorized api call.
   */

  addInterceptor(AuthInterceptor),
];
