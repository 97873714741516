import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-review-status-filter',
  templateUrl: './review-status-filter.component.html',
})
export class ReviewStatusFilterComponent implements OnInit {
  @Input() filterKey = '';
  @Output() filterID = new EventEmitter();

  reviewStatusOptions: Array<Select2OptionData> = [
    {
      id: 'All',
      text: 'All',
    },
    {
      id: 'API',
      text: 'API',
    },
    {
      id: 'Vendor',
      text: 'Vendor',
    },
    {
      id: 'Admin',
      text: 'Admin',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.filterKey = 'All';
  }

  changeFilter(data: any): void {
    this.filterID.emit(data);
  }
}
