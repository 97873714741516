<div class="row align-items-center">
  <div class="col-4" *ngIf="isThumbnailShow">
    <div class="upload-group-new justify-content-center">
      <div
        class="profile-picture"
        [ngClass]="circlePreview ? '' : 'normal-preview '"
      >
        <img
          [src]="imageUrl ? imageUrl : 'assets/images/img-placeholder.svg'"
          alt="profile-picture"
          [ngClass]="circlePreview ? 'rounded-circle' : ''"
          style="background-color: #fff"
        />
        <a
          *ngIf="imageUrl && canRemove"
          href="javascript: void(0)"
          class="delete-icon"
          (click)="removeImage()"
        >
          <img src="assets/images/delete-icon.svg" alt="trash" />
        </a>
      </div>
    </div>
    <div *ngIf="ratio" class="text-muted fs-10 text-center">
      Aspect ratio {{ ratio }}
    </div>
  </div>
  <div [ngClass]="{ 'col-8': isThumbnailShow, 'col-12': !isThumbnailShow }">
    <div class="drag-box">
      <div
        class="fm-upload-block drop-container"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <label
          class="upload-files-box"
          for="uploadFiles p-4"
          [ngClass]="{
            'doc-approved': imageStatus === applicationStatus.APPROVE,
            'doc-rejected': imageStatus === applicationStatus.REJECT
          }"
        >
          <img
            src="assets/images/cloud-upload-icon.svg"
            alt="upload file"
            style="background-color: #f1efef"
            class="text-center rounded-circle"
          />
          <div class="d-flex align-items center mb-2 fs-10">
            <a
              href="javascript: void(0)"
              (click)="uploadFile.click()"
              class="text-primary p-0"
            >
              Click to upload </a
            >&nbsp;
            <span class="text-muted"> or Drag and drop</span>
          </div>
          <span class="text-muted fs-10">
            {{ message }}
          </span>
          <input
            #uploadFile
            type="file"
            [accept]="allowFileTypes"
            id="uploadFiles"
            [disabled]="disabled"
            (change)="onFileChange($event)"
          />
        </label>
      </div>
    </div>
  </div>
</div>
