<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Add teammate</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      #closeMdl
      (click)="closeModal()"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body" [formGroup]="teamForm">
    <p>
      Invite members of your organization to start collaborating and carry out
      specific roles within the system.
    </p>
    <div class="form">
      <div class="form-group">
        <label>Email</label>
        <input
          type="text"
          class="form-control"
          placeholder="tony@Example.com"
          formControlName="email"
        />
        <div
          *ngIf="f['email'].invalid && f['email'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['email'].errors?.['required']"
            class="required-note text-danger"
          >
            Email is required.
          </div>
          <div
            *ngIf="f['email'].errors?.['pattern']"
            class="required-note text-danger"
          >
            Valid Email is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Role</label>
        <div>
          <ng-select2
            class="select2-control"
            *ngIf="rolesList.length > 0"
            name=""
            id="roleID123"
            [data]="rolesList"
            formControlName="role"
            [placeholder]="'Select'"
          >
          </ng-select2>
        </div>
        <div class="form-control" *ngIf="rolesList.length == 0">
          Please config the role first!
        </div>
        <div
          *ngIf="f['role'].invalid && f['role'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['role'].errors?.['required']"
            class="required-note text-danger"
          >
            Role is required.
          </div>
        </div>
      </div>
      <div class="form-group text-right">
        <button type="button" class="btn t-primary-btn" (click)="setTeammate()">
          <img src="assets/images/tickmark-white.svg" alt="submit" /> SUBMIT
        </button>
      </div>
    </div>
  </div>
</div>
