<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Edit Coupon Information</h4>
    <button
      type="button"
      #closeMdl
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="new-coupon-block" [formGroup]="couponForm">
      <div class="form-rows">
        <div class="form">
          <div class="form-group">
            <div class="left-title">
              <label>Code Name<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                formControlName="code"
                placeholder="Code Name"
              />
              <div
                *ngIf="f['code'].invalid && f['code'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="f['code'].errors && f['code'].errors['required']"
                  class="required-note text-danger"
                >
                  Code is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Type<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <div class="check-list">
                <div
                  class="c-radiobox"
                  *ngFor="let m of modeList; let i = index"
                >
                  <input
                    type="radio"
                    name="mode"
                    id="mode{{ i }}"
                    [value]="m.id"
                    formControlName="mode"
                  />
                  <label for="mode{{ i }}">{{ m.text }}</label>
                </div>
                <div
                  *ngIf="f['mode'].invalid && f['mode'].touched"
                  class="cross-validation-error-message"
                >
                  <div
                    *ngIf="f['mode'].errors && f['mode'].errors['required']"
                    class="required-note text-danger"
                  >
                    Mode is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Discount Value<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                placeholder="Discount Value"
                formControlName="discount"
                (keypress)="generalService.numberOnly($event)"
              />
              <div
                *ngIf="f['discount'].invalid && f['discount'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="
                    f['discount'].errors && f['discount'].errors['required']
                  "
                  class="required-note text-danger"
                >
                  Discount is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Usage Limit</label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                placeholder="Usage Limit"
                formControlName="usageLimit"
                (keypress)="generalService.numberOnly($event)"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Minimum Cart Value</label>
            </div>
            <div class="right-field">
              <input
                type="text"
                class="form-control"
                placeholder="Minimum Cart Value"
                formControlName="minCartValue"
                (keypress)="generalService.numberOnly($event)"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>Usage<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <ng-select2
                *ngIf="appliesToList.length > 0"
                class="select2-control"
                name="appliesTo"
                id="appliesTo"
                [data]="appliesToList"
                formControlName="appliesTo"
                [placeholder]="'Select'"
                (ngModelChange)="appliesToChange($event)"
              >
              </ng-select2>
              <div
                *ngIf="f['appliesTo'].invalid && f['appliesTo'].touched"
                class="cross-validation-error-message"
              >
                <div
                  *ngIf="
                    f['appliesTo'].errors && f['appliesTo'].errors['required']
                  "
                  class="required-note text-danger"
                >
                  Type is required.
                </div>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="form-group">
              <div class="left-title">
                <label>Status</label>
              </div>
              <div class="right-field">
                <div class="custom-control custom-switch label-left mt-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="couponStatus"
                    id="customSwitch1"
                  />
                  <label
                    class="custom-control-label"
                    for="customSwitch1"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-rows">
        <div class="card-box-header mb-3">
          <h4 class="card-title">Categories<span class="required">*</span></h4>
        </div>
        <div class="form">
          <div class="form-group">
            <ng-select2
              *ngIf="categoryList.length > 0"
              class="select2-control multiSelect2"
              name="category"
              id="category"
              [data]="categoryList"
              [placeholder]="'Select Category'"
              formControlName="category"
              [options]="options"
              (ngModelChange)="categoryChange($event)"
            >
            </ng-select2>
          </div>
          <div
            *ngIf="f['category'].invalid && f['category'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="
                f['appliesTo'].value &&
                f['category'].errors &&
                f['category'].errors['required']
              "
              class="required-note text-danger"
            >
              Category is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-rows" *ngIf="f['category'].value?.length">
        <div class="card-box-header mb-3">
          <h4 class="card-title">SKU’s</h4>
        </div>
        <div class="form">
          <div class="form-group">
            <ng-select2
              *ngIf="skuList.length > 0"
              class="select2-control multiSelect2"
              name="skuList"
              id="skuList"
              [data]="skuList"
              (valueChanged)="changeSKU($event)"
              [options]="options"
              [placeholder]="'Select SKU'"
              formControlName="sku"
            >
            </ng-select2>
          </div>
          <div
            class="cross-validation-error-message"
            *ngIf="skuList.length && !f['sku'].value?.length"
          >
            <div
              *ngIf="f['category'].value?.length && !f['sku'].value?.length"
              class="required-note text-danger"
            >
              SKU is required.
            </div>
          </div>
          <div
            class="selected-items-tag"
            *ngIf="!skuList?.length && f['category'].value?.length > 0"
          >
            Please add items for this category.
          </div>
        </div>
      </div>
      <div
        class="form-rows"
        *ngIf="f['sku']?.value?.length && packages.length > 0"
      >
        <div class="card-box-header mb-3">
          <h4 class="card-title">Package</h4>
        </div>
        <div class="form">
          <div class="form-group">
            <ng-select2
              *ngIf="packages.length > 0"
              class="select2-control multiSelect2"
              name="packages"
              id="packages"
              [data]="packages"
              [options]="options"
              [placeholder]="'Select'"
              formControlName="packages"
            >
            </ng-select2>
          </div>
        </div>
      </div>
      <div class="form-rows">
        <div class="card-box-header">
          <h4 class="card-title">Schedule</h4>
          <p>Use these settings to limit the coupon expiration date.</p>
        </div>
        <div class="form">
          <div class="form-group">
            <div class="left-title">
              <label>Start Date<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <div class="date-timerow">
                <div class="date-timecol">
                  <div class="date-control-group">
                    <input
                      type="date"
                      (keydown)="generalService.disableDate()"
                      (change)="changeDate(1)"
                      max="{{ todate | date : 'yyyy-MM-dd' }}"
                      class="form-control date-time-control"
                      formControlName="startDate"
                    />
                    <div
                      *ngIf="f['startDate'].invalid && f['startDate'].touched"
                      class="cross-validation-error-message"
                    >
                      <div
                        *ngIf="
                          f['startDate'].errors &&
                          f['startDate'].errors['required']
                        "
                        class="required-note text-danger"
                      >
                        Start date is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="date-timecol">
                  <input
                    type="time"
                    class="form-control"
                    placeholder="From Time"
                    formControlName="startTime"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="left-title">
              <label>End Date<span class="required">*</span></label>
            </div>
            <div class="right-field">
              <div class="date-timerow">
                <div class="date-timecol">
                  <div class="date-control-group">
                    <input
                      type="date"
                      (keydown)="generalService.disableDate()"
                      (change)="changeDate(2)"
                      min="{{ fromDate | date : 'yyyy-MM-dd' }}"
                      class="form-control date-time-control"
                      formControlName="endDate"
                    />
                    <div
                      *ngIf="f['endDate'].invalid && f['endDate'].touched"
                      class="cross-validation-error-message"
                    >
                      <div
                        *ngIf="
                          f['endDate'].errors && f['endDate'].errors['required']
                        "
                        class="required-note text-danger"
                      >
                        End date is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="date-timecol">
                  <input
                    type="time"
                    class="form-control"
                    placeholder="End Time"
                    formControlName="endTime"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btnrow">
      <button
        type="button"
        (click)="resetForm()"
        class="btn t-grey-btn-outline"
      >
        <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
      </button>
      <button type="button" (click)="save()" class="btn t-primary-btn">
        <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
      </button>
    </div>
  </div>
</div>
