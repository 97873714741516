<div class="modal-content wd-100">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">Change Email Address</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      #closeMdl
      (click)="resetForm()"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <div class="modal-body">
    <div class="profile-enter-new-email" [formGroup]="emailForm">
      <div class="mb-3">
        <label class="form-label"> Enter new email address</label>
        <input
          type="text"
          class="form-control"
          placeholder="Email"
          formControlName="email"
        />
        <div
          *ngIf="f['email'].invalid && f['email'].touched"
          class="cross-validation-error-message"
        >
          <div
            *ngIf="f['email'].errors?.['required']"
            class="required-note text-danger"
          >
            Email is required *
          </div>
          <div
            *ngIf="f['email'].errors?.['email']"
            class="required-note text-danger"
          >
            Valid Email is required *
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="floatingInput">Enter current Password</label>
        <div class="password-group">
          <input
            type="password"
            class="form-control"
            [type]="showPwd ? 'text' : 'password'"
            formControlName="password"
            placeholder="Current Password"
            autocomplete="new-password"
            name="password"
            (paste)="(false)"
          />
          <span
            class="show-pwd"
            id="show-hide-pwd"
            (click)="showPwd = !showPwd"
          >
            <img
              [src]="
                !showPwd
                  ? 'assets/images/pwd-off.svg'
                  : 'assets/images/show-hide-icon.svg'
              "
              alt="pwd-icon"
          /></span>
        </div>
      </div>

      <div class="bottom-btnrow mt-5">
        <button
          type="button"
          class="btn t-grey-btn-outline light-grey text-capitalize"
          (click)="resetForm()"
        >
          Reset
        </button>
        <button type="button" class="btn t-primary-btn" (click)="sendOTP()">
          save changes
        </button>
      </div>
    </div>
  </div>
</div>
