import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Provider,
  Renderer2,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
const TIME_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangePickerDirective),
  multi: true,
};

@Directive({
  selector: 'input[gfdDateRangePicker]',
  providers: [TIME_PICKER_CONTROL_VALUE_ACCESSOR],
  exportAs: 'DateRangePickerRef',
})
export class DateRangePickerDirective
  implements ControlValueAccessor, OnDestroy, AfterViewInit, OnChanges
{
  @Input() identifier!: string;
  @Input() options: AirDatepickerOptions = {};
  @Output() onload: EventEmitter<AirDatepicker> = new EventEmitter();

  onChange!: (value: string | object) => void;
  onTouched!: () => void;
  disable!: boolean;
  isOpen = false;
  dateRangePicker!: AirDatepicker;
  elementRef: ElementRef<HTMLInputElement>;

  defaultOptions: AirDatepickerOptions = {
    autoClose: true,
    buttons: ['clear', 'today'],
    locale: localeEn,
    onSelect: ({ date, formattedDate, datepicker }) => {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        formattedDate || ''
      );
      this.onChange(formattedDate || '');
      this.onTouched();
    },
  };
  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.elementRef = elementRef;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.show();
  }

  @HostListener('focus')
  onElementFocus(): void {
    this.show();
  }

  @HostListener('blur')
  onElementBlur(): void {
    this.show();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['options'].firstChange) {
      this.dateRangePicker?.destroy();
      this.initializeDatepicker();
    }
  }

  writeValue(value: any): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  registerOnChange(fn: (value: string | object) => void): void {
    this.onChange = (value: string | object) => {
      fn(value);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  ngAfterViewInit(): void {
    this.initializeDatepicker();
  }

  initializeDatepicker() {
    if (this.elementRef) {
      this.dateRangePicker = new AirDatepicker(this.elementRef.nativeElement, {
        ...this.defaultOptions,
        ...this.options,
      });
    }

    this.onload.emit(this.dateRangePicker);
  }

  ngOnDestroy(): void {
    if (this.dateRangePicker) {
      this.dateRangePicker.destroy();
    }
  }

  /**
   * show date range picker popup
   */
  show(): void {
    if (this.dateRangePicker && !this.isOpen) {
      this.dateRangePicker.show();
      this.isOpen = true;
    }
  }

  /**
   * hide date range picker popup
   */
  hide(): void {
    if (this.dateRangePicker && this.isOpen) {
      this.dateRangePicker.hide();
      this.isOpen = false;
    }
  }

  /**
   * toggle date range picker popup
   */
  toggle(): void {
    if (!this.isOpen) {
      this.show();
    } else {
      this.hide();
    }
  }
}
