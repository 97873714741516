import { Injectable } from '@angular/core';

import { IAppSettings } from '@app/shared/model/common-model';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  appSettingsUpdated: Subject<IAppSettings> = new Subject<IAppSettings>();
  appSettingsUpdated$: Observable<IAppSettings> =
    this.appSettingsUpdated.asObservable();
  private settings: IAppSettings | null = null;

  get appSettings(): IAppSettings | null {
    return this.settings;
  }

  setSettings(settings: IAppSettings) {
    this.settings = settings;
    this.appSettingsUpdated.next(this.settings);
  }

  updateSettings(infoKey: string, value: any) {
    const allSettings = this.settings;
    if (infoKey) {
      allSettings[infoKey] = value;
    }
    this.setSettings(allSettings);
  }
}
