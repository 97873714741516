import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private httpClient: HttpClient) {}

  // Coupons
  getCoupons(vendoId: string, payload: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}admin/coupon`);
  }

  setCoupon(payload: any): Observable<any> {
    if (payload.couponID) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}admin/coupon/${payload.couponID}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}admin/coupon`,
        payload
      );
    }
  }

  getCouponDetails(couponID: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/coupon/${couponID}`
    );
  }

  deleteCoupon(couponID: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}admin/coupon/${couponID}`
    );
  }

  enableDisableCoupons(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkCouponStatusUpdate`,
      payload
    );
  }

  bulkDeleteCoupons(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/coupon/delete `,
      payload
    );
  }
  getVendorList(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/vendor/?status=1&search=`
    );
  }
  getCategoryWiseSKUs(vendorID: string, payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/categorywiseskus/${vendorID}`,
      payload
    );
  }
}
