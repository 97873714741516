/* eslint-disable complexity */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAlphaNumeric]',
})
export class AlphanumericDirective {
  @Input() allowSpecialCharacters = '';
  @Input() allowSpace = true;
  @Input() allowNumber = true;

  previousValue = '';

  constructor(private hostElement: ElementRef) {}

  /**
   * Event handler for host's change event
   *
   * @param e
   */
  @HostListener('change', ['$event']) onChange(e: any) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  /**
   * Event handler for host's paste event
   *
   * @param e
   */
  @HostListener('paste', ['$event']) onPaste(e: any) {
    // get and validate data from clipboard
    const value = e.clipboardData.getData('text/plain');
    // test if valid value or not
    const regex: RegExp = this.getRegExp();
    const isValid: boolean = regex.test(value);
    if (!isValid) {
      this.hostElement.nativeElement.value = '';
      e.preventDefault();
    }
  }

  /**
   * Event handler for host's keydown event
   *
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    const cursorPosition: number = e.target.selectionStart;
    const originalValue: string = e.target.value;
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;

    // allowed keys apart from numeric characters
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Tab',
    ];

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    const regex: RegExp = this.getRegExp();

    const isAlphaNum = regex.test(key);
    if (isAlphaNum) {
      return;
    } else {
      e.preventDefault();
    }
  }

  validateValue(value: string) {
    const regex: RegExp = this.getRegExp();

    // test if valid value or not
    const isValid: boolean = regex.test(value);
    this.hostElement.nativeElement.value = isValid ? value : '';
  }

  getRegExp(): RegExp {
    let regex = this.allowNumber ? 'a-zA-Z0-9' : 'a-zA-Z';

    if (this.allowSpace) {
      regex += ' ';
    }

    if (this.allowSpecialCharacters) {
      regex += this.allowSpecialCharacters;
    }
    return new RegExp('^[' + regex + ']+$', 'g');
  }

  /**
   * Get key's name
   *
   * @param e
   */
  getName(e: any) {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numbpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }
}
