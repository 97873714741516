import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  // analytics
  getAnalyticsDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/dashboard/analytics?${payload}`
    );
  }

  getStatisticsData(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}dashboard?category=statistics`
    );
  }

  getLatestOrders(payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/dashboard/orders?${payload}`
    );
  }

  getSalesAnalytics(payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/dashboard/salesanalytics?${payload}`
    );
  }

  getTopSellingSKUs(payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/dashboard/analyticstopsellingsku?${payload}`
    );
  }

  // reports
  getReportsDetails(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/${vendorID}`
    );
  }

  getTotalEarning(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/total_earning/${vendorID}`
    );
  }

  getEarningReportDataForReports(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  getCustomReports(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  getCustomReportFields(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/reports/earning_reports/${vendorID}`
    );
  }

  // crm
  getCRMDetails(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/${vendorID}`
    );
  }

  getInvoiceList(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/invoice/${vendorID}?${payload}`
    );
  }

  getStatastics(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/statistics/${vendorID}?${payload}`
    );
  }

  //common
  getPopularProducts(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/analyticspopularproduct/${vendorID}`
    );
  }

  getOrders(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/orders/${vendorID}?${payload}`
    );
  }

  getEarningReportData(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/dashboard/crm/earning_reports/${vendorID}`
    );
  }

  //custom report
  getreportTemplateFields(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/report/customReportTemplateFields/${payload.template_id}`
    );
  }

  setReportTemplate(payload: any, vendorID: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/report/createReportTemplate/${vendorID}`,
      payload
    );
  }

  getCustomReportTemplats(vendorID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/report/customReportTemplates`
    );
  }

  getCustomReport(payload: any, template_id: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/report/customReports/${template_id}?${payload}`
    );
  }
}
