<div class="modal-content">
  <div class="modal-body">
    <button
      type="button"
      class="close"
      (click)="closeMdl()"
      data-dismiss="modal"
      aria-label="Close"
    >
      <img src="assets/images/close-outline1.svg" alt="close" />
    </button>
    <div class="text-center">
      <img
        src="assets/images/icon-check-done.svg"
        class="mb-3"
        alt="icon-check-done"
      />
      <h2 class="mb-4">Email Address Is Now Verified</h2>
      <p>Email Address has been updated successfully.</p>
    </div>
  </div>
</div>
