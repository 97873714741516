<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">OTP VERIFICATION</h4>
    <button
      type="button"
      class="close"
      (click)="reset()"
      #closeModal
      data-dismiss="modal"
      aria-label="Close"
    >
      <img src="assets/images/close-outline.svg" alt="close" />
    </button>
  </div>
  <!--Befor Verify OTP-->
  <div class="modal-body" *ngIf="!isOTPVerfied">
    <div class="verify-email-block">
      <p>
        OTP has been sent to register mobile Number
        <span class="d-inline-block">{{ mobile?.internationalNumber }}</span>
      </p>
      <div class="otp-form-group" [formGroup]="otpForm">
        <div class="otp-input-control">
          <input
            type="text"
            class="form-control"
            maxlength="1"
            formControlName="digitOne"
            #otpInput
            (keypress)="generalService.numberOnly($event)"
            (input)="handleInput(1)"
          />
          <input
            type="text"
            class="form-control"
            maxlength="1"
            formControlName="digitTwo"
            #otpInput
            (keypress)="generalService.numberOnly($event)"
            (input)="handleInput(2)"
          />
          <input
            type="text"
            class="form-control"
            maxlength="1"
            formControlName="digitThree"
            #otpInput
            (keypress)="generalService.numberOnly($event)"
            (input)="handleInput(3)"
          />
          <input
            type="text"
            class="form-control"
            maxlength="1"
            formControlName="digitFour"
            #otpInput
            (keypress)="generalService.numberOnly($event)"
          />
        </div>
        <a href="javascript: void(0)" (click)="resendOTP()" class="resend-otp"
          >Resend OTP in {{ counter }}s</a
        >
      </div>
      <div
        *ngIf="
          (f['digitOne'].invalid && f['digitOne'].touched) ||
          (f['digitTwo'].invalid && f['digitTwo'].touched) ||
          (f['digitThree'].invalid && f['digitThree'].touched) ||
          (f['digitFour'].invalid && f['digitFour'].touched)
        "
        class="cross-validation-error-message mb-2"
      >
        <div class="required-note text-danger">Please enter 4 digit OTP *</div>
      </div>
    </div>
    <div class="bottom-btnrow">
      <button
        type="button"
        class="btn t-primary-btn"
        (click)="payoutContinue ? verifyOTP() : verify()"
      >
        <img src="assets/images/tickmark-white.svg" alt="verify" />
        {{ payoutContinue ? 'VERIFY' : 'CONTINUE TO DELETE ACCOUNT' }}
      </button>
    </div>
  </div>
  <!--After Verify OTP-->
  <div class="modal-body" *ngIf="isOTPVerfied">
    <div class="request-payout-form">
      <div class="bank-acc-details">
        <div class="beneficiary-account-details">
          <div class="account-info">
            <ul>
              <li>
                <div class="left-label">Account Number</div>
                <div class="right-label">
                  {{ bankAccountDetails?.account_number }}
                </div>
              </li>
              <li>
                <div class="left-label">IBAN</div>
                <div class="right-label">{{ bankAccountDetails?.iban }}</div>
              </li>
              <li>
                <div class="left-label">Account Holder Name</div>
                <div class="right-label">{{ bankAccountDetails?.name }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="c-checkbox">
          <input
            type="checkbox"
            name=""
            id="checkBox"
            [(ngModel)]="isConfirm"
          />
          <label for="checkBox"
            >I confirm to delated above listed account details
          </label>
        </div>
      </div>
      <div class="bottom-btnrow">
        <button
          type="button"
          class="btn t-primary-btn t-primary-btn-small"
          (click)="deleteAccount()"
        >
          DELETE ACCOUNT
        </button>
      </div>
    </div>
  </div>
</div>
