import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUserInfo } from '@app/shared/model/common-model';

import { Observable, Subject } from 'rxjs';

/**
 * Provides storage for authenticated user detail.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialService {
  userInfoUpdated: Subject<IUserInfo> = new Subject<IUserInfo>();
  userInfoUpdated$: Observable<IUserInfo> = this.userInfoUpdated.asObservable();
  private userInfo: IUserInfo | null = null;

  constructor(public router: Router) {}

  /**
   * Gets the user detail.
   *
   * @return The user detail or null if the user is not authenticated.
   */
  get userDetails(): IUserInfo | null {
    return this.userInfo;
  }

  /**
   * Sets the user detail.
   * The detail are only persisted for the current session.
   *
   * @param userInfo The user detail.
   */
  setUserDetails(userInfo: IUserInfo) {
    this.userInfo = userInfo;
    this.userInfoUpdated.next(this.userInfo);
  }

  updateCredentials(infoKey: string, value: any) {
    const userDetail = this.userInfo;
    if (userDetail) {
      if (infoKey) {
        userDetail[infoKey] = value;
      }
      this.setUserDetails(userDetail);
    }
  }
}
