import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  constructor(private httpClient: HttpClient) {}

  //commission payout
  getPayOutList(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/commission-payout/${vendorID}?${payload}`
    );
  }

  //credit memo
  getCreditNotes(vendorID: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/creditnotelist/${vendorID}?${payload}`
    );
  }

  bulkDeleteCreditNots(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/bulkCreditDelete`,
      payload
    );
  }
}
