import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { DateRangePickerDirective } from './date-range-picker.directive';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [DateRangePickerDirective],
  exports: [DateRangePickerDirective],
})
export class DateRangePickerModule {}
