import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  StorageService,
  GeneralService,
  ValidationService,
} from '@app/core/services';
import { SupportService } from '@app/core/services/support.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-admin-support-ticket-modal',
  templateUrl: './add-admin-support-ticket-modal.component.html',
})
export class AddAdminSupportTicketModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeModal') closeModal: any;
  @Input() page: any;
  @Input() id: any;

  modules: Array<Select2OptionData> = [
    {
      id: 'products',
      text: 'Product',
    },
    {
      id: 'services',
      text: 'Service',
    },
    {
      id: 'coupons',
      text: 'Coupon',
    },
  ];
  moduleFields: Array<Select2OptionData> = [];
  fields: any = [];

  ticketData = new UntypedFormGroup({
    subject: new UntypedFormControl('', [ValidationService.required]),
    module: new UntypedFormControl('', [ValidationService.required]),
    description: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    private supportService: SupportService,
    private storageService: StorageService,
    private generalService: GeneralService
  ) {
    super();
  }

  ngOnInit(): void {}

  getTableFields(data: any): void {
    const payload = {
      module: data,
    };
    this.supportService
      .getTableFields(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.moduleFields = res?.data;
            this.fields = [];
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  selectFields(event: any): void {
    this.fields?.push(event);
    const unique = this.fields?.filter(
      (obj: any, index: any) =>
        this.fields?.findIndex((item: any) => item == obj) == index
    );
    this.fields = unique;
  }

  removeField(index: any): void {
    this.fields?.splice(index, 1);
  }

  save(): void {
    if (this.ticketData.invalid) {
      this.ticketData.markAllAsTouched();
    } else if (this.fields?.length === 0) {
      this.generalService.displayWarning('Please select fields');
    } else {
      const payload = {
        subject: this.ticketData.value?.subject,
        description: this.ticketData.value?.description,
        order: this.ticketData.value?.order,
        seller: this.storageService.getCookie('userID'),
        status: 0, // 0 => open, 1=> close
        tickets_by: 1, //0 => customer to seller, 1=> seller to admin,
        module: this.ticketData.value?.module,
        fields_to_edit: this.fields,
        service_id: this.page === 'service' ? this.id : '',
        product_id: this.page === 'product' ? this.id : '',
        coupon_id: this.page === 'coupon' ? this.id : '',
      };
      this.supportService.addTicket(payload).subscribe({
        next: (res) => {
          this.generalService.displaySuccess(res.message);
          this.closeModal?.nativeElement?.click();
        },
        error: (error) => {
          console.log('err', error);
        },
      });
    }
  }

  get f() {
    return this.ticketData.controls;
  }
}
