import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

import { AppSettingsService } from '../services/app-setting.service';
import { StorageService } from '../services/storage.service';

export function AppSettingsInitializerFactory(
  http: HttpClient,
  localStorage: StorageService,
  appSettingsService: AppSettingsService
): () => Promise<any> {
  return (): Promise<any> =>
    new Promise((resolve) => {
      if (localStorage.getCookie(`AccessToken`)) {
        http
          .get(`${environment.baseApiUrl}admin/settings/getSiteSettings`)
          .subscribe((response: any) => {
            if (response && response.success) {
              appSettingsService.setSettings(response?.data);
              resolve(true);
            } else {
              resolve(true);
            }
          });
      } else {
        return resolve(true);
      }
    });
}
