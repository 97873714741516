import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configTime',
})
export class ConfigTimePipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    let changedValue: string | null = '';
    if (value) {
      switch (args[0]) {
        case 'HH:MM':
          changedValue = new DatePipe('en-US').transform(value, 'HH:mm');
          break;
        case 'H:MM a':
          changedValue = new DatePipe('en-US').transform(value, 'hh:mm a');
          break;
        default:
          changedValue = new DatePipe('en-US').transform(value, 'HH:mm');
      }
    }
    return changedValue;
  }
}
