export interface IPageList {
  modified_date: string;
  modified_status: string;
  section: number;
  status: number;
  title: string;
  type: number;
  updatedAt: string;
  isSelected: boolean;
  _id: string;
}
export enum PAGE_STATUS {
  HOMEPAGE = 0,
  ABOUT_US = 1,
  CUSTOMER_CARE = 2,
  FAQ = 3,
  PRIVACY_POLICY = 4,
  TERMS_AND_CONDITIONS = 5,
  SERVICE_LANDING = 6,
  PRODUCT_LANDING = 7,
}
export interface IPageDetails {
  heading: string;
  is_deleted: number;
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
  section: number;
  status: number;
  title: string;
  type: number;
  _id: string;
  configId: string;
}

export enum PAGE_CONTENT {
  Flash = 'FLASH',
  Banner = 'BANNER',
  Category = 'CATEGORY',
  ServiceGrid = 'SERVICE_GRID',
  ServiceCarouse = 'SERVICE_CAROUSE',
  ProductGrid = 'PRODUCT_GRID',
  ProductCarouse = 'PRODUCT_CAROUSE',
  NewOnShelves = 'NEW_ON_SHELVES',
  EvergreenBestsellers = 'EVERGREEN_BESTSELLERS',
  BooksEveryOneTalkingAbout = 'BOOKS_EVEYONE_TALKING',
  AwardWinning = 'AWARD_WINNING',
}
