<div class="modal-content">
  <div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <img src="assets/images/close-outline1.svg" alt="close" />
    </button>
    <div class="text-center">
      <img
        src="assets/images/icon-check-done.svg"
        class="mb-3"
        alt="icon-check-done"
      />
      <h2 class="mb-4">Phone Number Is Now Verified</h2>
      <p>Phone Number has been updated successfully.</p>
    </div>
  </div>
</div>
