import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-request-admin-to-edit-modal',
  templateUrl: './request-admin-to-edit-modal.component.html',
})
export class RequestAdminToEditModalComponent {
  @ViewChild('closeModal') closeModal: any;
  @Output() openTickeModal = new EventEmitter();

  openTicketModal(): void {
    this.closeReqModal();
    this.openTickeModal.emit();
  }

  closeReqModal(): void {
    this.closeModal?.nativeElement.click();
  }
}
