import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-records',
  templateUrl: './no-records.component.html',
})
export class NoRecordsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
