<div class="row">
  <div class="checklist-item">
    <div
      class="checklist-item border-bottom py-3"
      *ngFor="let item of checkList; let i = index"
    >
      <div class="d-flex align-items-center mb-2">
        <span
          class="check-list-icon rounded-circle d-flex align-items-center p-2"
        >
          <img [src]="item.logo" alt="image" />
        </span>
        <h6 class="px-3">{{ item.description }}</h6>
      </div>

      <div class="checklist-radio-row d-flex">
        <button
          type="button"
          id="rejected"
          class="btn custom-control rejected"
          [ngClass]="
            item.status === applicationStatus.REJECT
              ? ' btn-danger'
              : 'btn-outline-secondary'
          "
          (click)="changeStatus(item, applicationStatus.REJECT, i)"
        >
          <span class="fs"> Something's wrong</span>
        </button>
        <button
          type="button"
          id="approved"
          class="btn custom-control approved"
          [ngClass]="
            item.status === applicationStatus.APPROVE
              ? 't-primary-btn'
              : 't-grey-btn-outline'
          "
          (click)="changeStatus(item, applicationStatus.APPROVE, i)"
        >
          <span class="fs"> Looks good</span>
        </button>
      </div>
      <div *ngIf="item.status === applicationStatus.REJECT">
        <label for="{{ item.logType }}-reason"></label>
        <div class="reason-control">
          <input
            type="text"
            id="{{ item.logType }}-reason"
            class="form-control"
            placeholder="Reason"
            [(ngModel)]="item.reason"
          />
          <span class="cross-icon d-flex align-items-center rounded-circle">
            <img [src]="'assets/images/cross-icon-red.svg'" alt="cross-icon"
          /></span>
        </div>
        <div *ngIf="!item.reason && isSubmitted" class="form-text text-danger">
          reason required
        </div>
      </div>
      <div
        *ngIf="item.status === null && isSubmitted"
        class="form-text text-danger"
      >
        approval required
      </div>
    </div>
  </div>
</div>
