export interface IVendor {
  _id: string;
  firstName: string;
  lastName: string;
  mobileNumber: any;
  email: string;
  role: string;
  status: string;
  profileImage: string;
  password: string;
  role_name: string;
  role_type: number;
  seller: string;
  sellerName: string;
  category_access: number;
  content_management: number;
  dashboard: number;
  data_transfer_access: number;
  finances_access: number;
  module_access: number;
  product_access: number;
  promotions_access: number;
  reports_access: number;
  reviews_access: number;
  service_access: number;
  shipping_delivery: number;
  user_management: number;
  account_settings_access: number;
}

export interface IVendorData {
  _id: string;
  full_name: string;
  status: number;
  sellerId: string;
}

export interface IVendorData {
  _id: string;
  full_name: string;
  status: number;
}

export interface IVendorList extends IVendor {
  isSelected: boolean;
}
export interface ICheckListItem {
  date: string;
  description: string;
  logType: string;
  reason: string;
  status: number;
  logo?: string;
  isSubmitted?: boolean;
}
export enum APPLICATION_STATUS {
  PENDING = 0,
  RESUBMIT = 1,
  REJECT = 2,
  APPROVE = 3,
}

export enum VENDOR_STATUS {
  ENABLED = 1,
  DISABLED = 0,
  UNDER_REVIEW = 2,
  REJECTED = 3,
}
