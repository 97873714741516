import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';

import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivateChild {
  constructor(private storageService: StorageService, private router: Router) {}

  canActivateChild(): boolean {
    if (
      !this.storageService.getCookie('AccessToken') ||
      !this.storageService.getCookie('userID')
    ) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
