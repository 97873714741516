import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileType',
})
export class FileTypePipe implements PipeTransform {
  transform(
    value: string,
    fileType: string | string[],
    ...args: unknown[]
  ): unknown {
    const typeArr = value?.split('.');
    if (typeof fileType === 'string') {
      return typeArr[typeArr.length - 1]
        ? typeArr[typeArr.length - 1] === fileType
        : false;
    } else {
      return typeArr[typeArr.length - 1] && fileType.length
        ? fileType.includes(typeArr[typeArr.length - 1])
        : false;
    }
  }
}
