<div class="modal-header">
  <h4 class="modal-title text-uppercase">PAYOUT REQUEST</h4>
  <button
    type="button"
    class="close"
    (click)="closeSentModal()"
    data-dismiss="modal"
    aria-label="Close"
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="text-center">
    <img src="assets/images/icon-check-done.svg" alt="icon-check-done" />
    <h2 class="font-weight-bold my-3">Request Sent</h2>
    <p>Admin Team will get back to you within 24 Hours.</p>
  </div>
</div>
