import { Pipe, PipeTransform } from '@angular/core';

import * as beautify from 'js-beautify';

@Pipe({
  name: 'codeFormatter',
})
export class CodeFormatterPipe implements PipeTransform {
  transform(code: string): string {
    try {
      const parsedCode = JSON.parse(code);
      const formattedCode = beautify(
        JSON.stringify(this.recursivelyFormatObjects(parsedCode), null, 2),
        {
          indent_size: 2, // Number of spaces to use for indentation
          space_in_empty_paren: true, // Add a space inside empty parens (e.g., { })
          e4x: true, // Allow E4X syntax (XML literals) to be reformatted
          preserve_newlines: true, // Preserve line breaks
          max_preserve_newlines: 2, // Maximum number of line breaks to preserve
          end_with_newline: true, // Add a newline at the end of the file
          brace_style: 'collapse', // Brace style for control statements (e.g., if, for)
          wrap_line_length: 80, // Wrap lines at a specific length
          wrap_attributes: 'auto', // Wrap attributes to a new line (auto, force, force-aligned, force-expand-multiline)
          // Add more options as needed
        }
      );
      return formattedCode;
    } catch (error) {
      console.error('Error formatting code:', error);
      return code;
    }
  }

  /**
   * Recursively format any nested objects
   * @param obj
   * @returns
   */
  private recursivelyFormatObjects = (obj: any): any => {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        try {
          // Attempt to parse the string as JSON
          obj[key] = JSON.parse(obj[key]);
          // If successful, recursively format the parsed object
          obj[key] = this.recursivelyFormatObjects(obj[key]);
        } catch (parseError) {
          // If parsing fails, keep the original string value
        }
      } else if (typeof obj[key] === 'object') {
        // If the value is an object, recursively format it
        obj[key] = this.recursivelyFormatObjects(obj[key]);
      }
    }
    return obj;
  };
}
