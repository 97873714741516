import { Attribute, Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[gfdEqualValidator][formControlName],[gfdEqualValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidator),
      multi: true,
    },
  ],
  standalone: true,
})
export class EqualValidator implements Validator {
  @Input() oppositeLabel?: string = '';
  @Input() inputType: 'string' | 'number' | 'date' | 'time' | 'dateTime' =
    'string';
  @Input() operator:
    | 'equal'
    | 'notEqual'
    | 'greaterThan'
    | 'lessThan'
    | 'greaterThanEqual'
    | 'lessThanEqual' = 'equal';

  constructor(
    @Attribute('gfdEqualValidator') public equalValidator: string,
    @Attribute('reverseError') public reverseError: string,
    @Attribute('reverse') public reverse: string
  ) {}

  validate(c: AbstractControl): { [key: string]: any } {
    // self value
    const v = c.value;

    // control vlaue
    const e = c.root.get(this.equalValidator);

    let value1 = v;
    let value2 = e.value;
    switch (this.inputType) {
      case 'number':
        value1 = +value1;
        value2 = +value2;
        break;
      case 'date':
      case 'dateTime':
        value1 = Date.parse(value1);
        value2 = Date.parse(value2);
        break;
      case 'time':
        value1 = Date.parse('0 ' + value1);
        value2 = Date.parse('0 ' + value2);
        break;

      default:
        break;
    }

    if (e && this.compareValue(value1, value2)) {
      if (this.isReverse && e.getError('misMatched')) {
        delete e.errors.misMatched;
        if (!Object.keys(e.errors).length) {
          e.setErrors(null);
        }
      }
    } else {
      if (this.isReverse) {
        e.setErrors({
          misMatched: this.oppositeLabel
            ? this.oppositeLabel
            : this.equalValidator,
        });
      } else {
        return {
          misMatched: this.oppositeLabel
            ? this.oppositeLabel
            : this.equalValidator,
        };
      }
    }
    return null;
  }

  private get isReverse() {
    if (!this.reverseError) {
      return false;
    }
    return this.reverseError === 'true' ? true : false;
  }

  compareValue(value1, value2): boolean {
    if (!value1 && !value2) {
      return true;
    }
    switch (this.operator) {
      case 'equal':
        return value1 === value2;
      case 'notEqual':
        return value1 !== value2;
      case 'greaterThan':
        return this.reverse ? value1 < value2 : value1 > value2;

      case 'lessThan':
        return this.reverse ? value1 > value2 : value1 < value2;

      case 'greaterThanEqual':
        return this.reverse ? value1 <= value2 : value1 >= value2;

      case 'lessThanEqual':
        return this.reverse ? value1 >= value2 : value1 <= value2;
    }
  }
}
