<div class="modal-header">
  <h4 class="modal-title">Reject Reason</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    #cancelModal
  >
    <img src="assets/images/close-outline.svg" alt="close" />
  </button>
</div>
<div class="modal-body">
  <div class="commit-modal-content">
    <div class="form-group" [formGroup]="reasonForm">
      <div class="field-with-action">
        <div class="tags-control" style="width: 100%">
          <textarea
            placeholder="Enter reject reason"
            formControlName="customReason"
            class="form-control reject-control"
            [maxlength]="rejectReasonMaxLength"
          ></textarea>
          <span class="d-flex justify-content-end text-muted mt-1"
            >{{
              f['customReason']?.value?.length
                ? f['customReason'].value.length
                : 0
            }}/{{ rejectReasonMaxLength }}</span
          >
        </div>
      </div>
      <div
        *ngIf="f['customReason'].invalid && f['customReason'].touched"
        class="cross-validation-error-message"
      >
        <div
          *ngIf="
            f['customReason'].errors && f['customReason'].errors['required']
          "
          class="required-note text-danger"
        >
          Reject Reason is required.
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-btnrow justify-content-start">
    <button class="btn t-primary-light text-capitalize" (click)="cancel()">
      Cancel
    </button>
    <button
      class="btn t-primary-btn text-capitalize"
      [disabled]="reasonForm.invalid || !this.reasonForm.value.customReason"
      (click)="save()"
    >
      Submit
    </button>
  </div>
</div>
