import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import {
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { CatalogManagementService } from '@app/core/services/catalog-management.service';
import { PromotionService } from '@app/core/services/promotion.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { Select2OptionData } from 'ng-select2';
import { Observable, takeUntil } from 'rxjs';
import { Options } from 'select2';

@Component({
  selector: 'app-edit-coupon-modal',
  templateUrl: './edit-coupon-modal.component.html',
  styleUrls: ['./edit-coupon-modal.component.scss'],
})
export class EditCouponModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnChanges
{
  @ViewChild('closeMdl') closeMdl: any;
  @Input() couponID: any = 0;
  @Output() modalClose = new EventEmitter();

  couponForm!: UntypedFormGroup;
  monthList: Array<Select2OptionData> = [];
  modeList: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Percentage',
    },
    {
      id: '1',
      text: 'Fixed Amount',
    },
  ];
  appliesToList: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Service Only',
    },
    {
      id: '1',
      text: 'Product Only',
    },
    {
      id: '2',
      text: 'Both',
    },
  ];
  start_date = '';
  end_date = '';
  categoryList: Array<any> = [];
  options: Options = {
    multiple: true,
  };
  serviceList: Array<any> = [];
  productList: Array<any> = [];
  skuList: Array<any> = [];
  productCategories: any = [];
  serviceCategories: any = [];
  todate: any;
  fromDate: any;
  selectedServiceData: any;
  packages: any = [];

  constructor(
    private promotionService: PromotionService,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private catalogMgtService: CatalogManagementService
  ) {
    super();
  }

  get f() {
    return this.couponForm.controls;
  }

  ngOnInit(): void {
    this.prepareForm();
    // this.getCategories(this.catalogMgtService.getCategories(0), 0);
    // this.getCategories(this.catalogMgtService.getCategories(1), 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['couponID']?.currentValue) {
      if (this.couponID) {
        // this.getCouponDetails();
        this.cartValueChange();
      }
    }
  }

  prepareForm(): void {
    this.couponForm = new UntypedFormGroup({
      code: new UntypedFormControl('', ValidationService.required),
      mode: new UntypedFormControl('', ValidationService.required),
      discount: new UntypedFormControl('', ValidationService.required),
      usageLimit: new UntypedFormControl(''),
      couponStatus: new UntypedFormControl('1'),
      startDate: new UntypedFormControl('', ValidationService.required),
      endDate: new UntypedFormControl('', ValidationService.required),
      startTime: new UntypedFormControl('00:00'),
      endTime: new UntypedFormControl('00:00'),
      appliesTo: new UntypedFormControl('', [ValidationService.required]),
      category: new UntypedFormControl('', [ValidationService.required]),
      sku: new UntypedFormControl('', [ValidationService.required]),
      minCartValue: new UntypedFormControl(''),
      packages: new UntypedFormControl(''),
    });
  }

  getCouponDetails(): void {
    this.promotionService
      .getCouponDetails(this.couponID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          const startDate = new Date(response.data.start_date)
            ?.toISOString()
            .split('T');
          const endDate = new Date(response.data.end_date)
            ?.toISOString()
            .split('T');
          this.couponForm.patchValue({
            code: response.data.code || '',
            mode:
              response.data.type == 'Percentage'
                ? '0'
                : response.data.type == 'Fixed Amount'
                ? '1'
                : '2',
            discount: response.data?.discount || 0,
            usageLimit: response.data?.uses_limit || 0,
            startDate: startDate[0] || '',
            endDate: endDate[0] || '',
            startTime: response.data.start_time || '00:00',
            endTime: response.data.end_time || '00:00',
            appliesTo: response.data.applies_to + '' || '',
            category: response.data.category?.length
              ? response.data.category.map((m: any) => m.id)
              : [],
            sku:
              response.data.sku?.length > 0
                ? response.data.sku.map((m: any) => m.id)
                : [],
            minCartValue: response.data?.minimum_cart_value,
            couponStatus: response?.data?.status,
            packages:
              response.data?.package?.length > 0
                ? response.data?.package?.map((m: any) => m.id)
                : [],
          });
          this.fromDate = startDate[0];
          this.todate = endDate[0];
          this.categoryChange(this.couponForm.value.category);
        }
      });
  }

  save(): void {
    if (this.couponForm.invalid) {
      this.couponForm.markAllAsTouched();
      return;
    } else {
      if (this.checkTime()) {
        this.generalService.displayError('Check start time and end time.');
        return;
      }
      const {
        code,
        mode,
        discount,
        usageLimit,
        startDate,
        endDate,
        startTime,
        endTime,
        appliesTo,
        sku,
        category,
        minCartValue,
        couponStatus,
        packages,
      } = this.couponForm.getRawValue();
      const payload = {
        vendor: this.storageService.getCookie('userID'),
        code: code ? code : '',
        type: mode
          ? mode == 0
            ? 'Percentage'
            : mode == 1
            ? 'Fixed Amount'
            : 'Max Limit'
          : '',
        discount: discount ? discount : 0,
        uses_limit: usageLimit ? usageLimit : 0,
        start_date: startDate ? startDate : '',
        end_date: endDate ? endDate : '',
        start_time: startTime,
        end_time: endTime,
        couponID: this.couponID ? this.couponID : 0,
        applies_to: appliesTo,
        category: this.createCategoryPayload(category),
        sku: this.createSkuPayload(sku),
        package:
          this.packages?.length > 0 && appliesTo != 1
            ? this.createPackagePayload(packages)
            : [],
        minimum_cart_value: minCartValue ? minCartValue : 0,
        status: couponStatus,
      };
      this.promotionService
        .setCoupon(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.resetForm();
              this.modalClose.emit({ onSaveClose: true });
              this.closeMdl.nativeElement.click();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  resetForm(): void {
    this.couponForm.reset();
    this.todate = '';
    this.fromDate = '';
    this.categoryList = [];
    this.skuList = [];
  }

  changeDate(id: any): void {
    if (id == 1) {
      this.fromDate = this.couponForm?.value?.startDate;
    } else {
      this.todate = this.couponForm?.value?.endDate;
    }
  }

  checkTime(): boolean {
    if (this.fromDate && this.todate && this.fromDate === this.todate) {
      const { startTime, endTime } = this.couponForm.value;
      const startSeconds = this.getSeconds(startTime);
      const endSeconds = this.getSeconds(endTime);
      return startSeconds > endSeconds;
    } else {
      return false;
    }
  }

  getSeconds(time: string): number {
    const timeParts = time?.split(':');
    return +timeParts?.[0] * 60 + +timeParts?.[1];
  }

  appliesToChange(value: string): void {
    const prevValue = this.couponForm.value.category;
    if (value === '2') {
      // both
      this.categoryList =
        this.serviceCategories.concat(this.productCategories) || [];
    } else if (value === '1') {
      // product
      this.categoryList = this.productCategories || [];
    } else {
      // service
      this.categoryList = this.serviceCategories || [];
    }
    const currentValue = prevValue
      ? prevValue?.filter(
          (v: string) => this.categoryList.findIndex((s) => s.id === v) >= 0
        ) || ''
      : '';
    this.couponForm.controls['category'].setValue(
      currentValue.length ? currentValue : ''
    ); // category should be reset with new value
  }

  categoryChange(event: Array<string>): void {
    const minimum_cart_value = this.couponForm.value.minCartValue;
  }

  getCategories(api: Observable<any>, val: number): void {
    api.subscribe(
      (res) => {
        if (val === 0) {
          res.data.forEach((i: any) => {
            this.serviceCategories.push({
              id: i.id,
              text: i.name,
            });
          });
        } else {
          res.data.forEach((i: any) => {
            this.productCategories.push({
              id: i.id,
              text: i.name,
            });
          });
        }
      },
      (err) => {}
    );
  }

  createCategoryPayload(category: Array<string>): Array<any> {
    const modified: Array<any> = [];
    category.forEach((c) => {
      const arr = this.categoryList.find((f) => f.id === c);
      if (arr) modified.push(arr);
    });
    return modified;
  }

  createSkuPayload(sku: Array<string>): Array<any> {
    const modified: Array<any> = [];
    sku.forEach((s) => {
      const arr = this.skuList.find((f) => f.id === s);
      if (arr) modified.push(arr);
    });
    return modified;
  }

  createPackagePayload(packages: Array<string>): Array<any> {
    const modified: Array<any> = [];
    packages.forEach((s) => {
      const arr = this.packages?.find((f: any) => f.id == s);
      if (arr) modified.push(arr);
    });
    return modified;
  }

  closeModal(): void {
    this.modalClose.emit();
  }

  cartValueChange(): void {
    this.couponForm.controls['minCartValue'].valueChanges.subscribe((val) => {
      const categoryList = this.couponForm.value.category;
      this.skuList = [];
      if (categoryList && categoryList?.length) {
        // this.couponWiseSKUList(categoryList, +val);
      }
    });
  }

  changeSKU(data: any): void {
    for (let i = 0; i < data?.length; i++) {
      const serviceData = this.selectedServiceData?.filter(
        (obj: any) => obj.sku == data[i] || obj.id == data[i]
      );
      const packageData = serviceData?.[0]?.package_data;
      const isDuplicate =
        this.packages?.filter((s: any) => s?.service_id == serviceData[0]?._id)
          ?.length > 0;
      if (!isDuplicate) {
        for (let j = 0; j < packageData?.length; j++) {
          const packageName =
            packageData[j]?.is_default_package == 1
              ? packageData[j]?.title
              : packageData[j]?.name;
          this.packages = [
            ...this.packages,
            {
              id: packageData[j]?.id + '-' + packageName,
              text: packageName
                ? packageName + ' (' + serviceData[0]?.sku + ')'
                : '-',
              isDefaultPackage: packageData[j]?.is_default_package,
              service_id: serviceData[0]?._id,
              package_name: packageName,
              package_id: packageData[j]?.id,
            },
          ];
        }
      }
    }
  }
}
