import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  constructor(private httpClient: HttpClient) {}

  // Vendor Details Profile, company and store
  getVendorDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/details/${payload.seller_id}`
    );
  }

  getUserIdentityDetails(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}admin/identity`);
  }

  getAdminDetails(adminId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/details/${adminId}`
    );
  }

  getVendorAccountDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/accountdetails/${payload.seller_id}`
    );
  }

  setVendorDetails(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/update/${payload.seller_id}`,
      payload
    );
  }

  // Profile
  editEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/updateEmail/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  changeEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/email`,
      payload
    );
  }

  verifyEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/verifyEmail`,
      payload
    );
  }

  editPassword(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/password`,
      payload
    );
  }

  deActivateAccount(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/deactivate/${payload.seller_id}`,
      payload
    );
  }

  getCountryList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/country`);
  }

  getLanguages(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/language`);
  }

  getCityList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseApiUrl}vendor/citylist`);
  }

  editMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      // `${environment.baseApiUrl}vendor/updateMobile/sendOtp/${payload.seller_id}`,
      `${environment.baseApiUrl}admin/updateMobile/sendOtp/${payload.seller_id}`,
      payload
    );
  }

  verifyMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      // `${environment.baseApiUrl}vendor/verifyAndUpdateMobile/${payload.seller_id}`,
      `${environment.baseApiUrl}admin/verifyAndUpdateMobile/${payload.seller_id}`,
      payload
    );
  }

  // Teams
  getTeamsList(sellerId: string, payload: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/teamMemberList/${sellerId}?${payload}`
    );
  }

  setTeammate(payload: any): Observable<any> {
    if (payload.teammate_id) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/teamMember/${payload.teammate_id}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/teamMember`,
        payload
      );
    }
  }

  setTeamRegistration(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}vendor/updateTeammember`,
      payload
    );
  }

  getTeamDetails(teammate_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/teamMember/${teammate_id}`
    );
  }

  deleteTeammate(teammate_id: any): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.baseApiUrl}vendor/teamMember/${teammate_id}`
    );
  }

  uploadTeamProfileImage(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teamMember/profile`,
      payload
    );
  }

  sendTeamMemberOTP(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teammember/sendOtp`,
      payload
    );
  }

  verifyTeamMobileNumber(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/teammember/verifyOtp`,
      payload
    );
  }

  checkAccountStatus(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}vendor/team/checkAccountStatus`,
      payload
    );
  }

  // Role
  getRolesList(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/role/list?${payload}`
    );
  }

  setRole(payload: any): Observable<any> {
    if (payload.role_id) {
      return this.httpClient.put<any>(
        `${environment.baseApiUrl}vendor/role/${payload.role_id}`,
        payload
      );
    } else {
      return this.httpClient.post<any>(
        `${environment.baseApiUrl}vendor/addRole`,
        payload
      );
    }
  }

  bulkUpdateRoleStatus(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/role/status`,
      payload
    );
  }

  bulkDeleteRole(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/role/delete`,
      payload
    );
  }

  getRoleDetails(role_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/role/${role_id}`
    );
  }

  checkRole(role_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}vendor/checkRole/${role_id}`
    );
  }
}
