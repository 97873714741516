<li class="notification-item bell">
  <a
    href="javascript: void(0)"
    (click)="openModal()"
    #notificationButton
    [ngClass]="{ active: isNotificationModalOpen }"
  >
    <img src="assets/images/bell-icon.svg" alt="notification-icon" />
    <span class="{{ isNewNotificationGet ? 'notification-badge' : '' }}"></span>
  </a>
  <div
    class="notify-menu"
    #notificationDiv
    [ngClass]="{ open: isNotificationModalOpen }"
    [ngStyle]="{ display: !isNotificationModalOpen ? 'none' : 'block' }"
  >
    <div class="notify-menu-head">
      <ul class="tabs">
        <li
          class="tab-link"
          data-tab="all"
          [ngClass]="{ active: currentTab === 'all' }"
          (click)="getInitialNotifications()"
        >
          <a href="javascript:void(0);"
            >All <span class="count"> {{ totalNotiCount }}</span></a
          >
        </li>
        <li
          class="tab-link"
          data-tab="request"
          [ngClass]="{ active: currentTab === 'request' }"
          (click)="getInitialNotifications(true)"
        >
          <a href="javascript:void(0);">
            Request
            <span class="count">
              {{ totalInvCount }}
            </span></a
          >
        </li>
      </ul>
    </div>
    <div class="notify-menu-content">
      <div class="tab-info">
        <div
          id="all"
          class="tab-content"
          [ngClass]="{ current: currentTab === 'all' }"
        >
          <ul class="notification-listing" *ngIf="allNotiList">
            <li
              (click)="markAsReadNotification(item, false)"
              class="{{ !item.is_read ? 'new-notification' : '' }}"
              *ngFor="let item of allNotiList"
            >
              <div class="image">
                <img
                  *ngIf="item?.customer_profile"
                  [src]="
                    item?.customer_profile
                      ? item?.customer_profile
                      : 'assets/images/avatar-img-1.png'
                  "
                  alt="avatar-img"
                />
              </div>
              <div class="content">
                <div class="info">
                  <h6 *ngIf="!item?.name">
                    {{ item?.name }}
                  </h6>
                  <h6 *ngIf="item?.name">
                    {{ item?.name || '' }}
                  </h6>
                  <p>{{ item?.module_event }}</p>
                </div>
                <div class="dt-info">
                  <div class="dt">
                    {{ item?.createdAt | date : 'dd/MM/yyyy' }}
                  </div>
                  <div class="dt">
                    {{ item?.createdAt | date : 'hh:mm a' }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div *ngIf="allNotiList?.length == 0">
            No records found.
          </div>
        </div>
        <div
          id="all"
          class="tab-content"
          [ngClass]="{ current: currentTab === 'request' }"
        >
          <ul class="notification-listing" *ngIf="requestNotiList">
            <li
              (click)="markAsReadNotification(item, true)"
              class="{{ !item.is_read ? 'new-notification' : '' }}"
              *ngFor="let item of requestNotiList"
            >
              <div class="image">
                <img
                  *ngIf="item?.customer_profile"
                  [src]="
                    item?.customer_profile
                      ? item?.customer_profile
                      : 'assets/images/avatar-img-1.png'
                  "
                  alt="avatar-img"
                />
              </div>
              <div class="content">
                <div class="info">
                  <h6 *ngIf="!item?.name">
                    {{ item?.name }}
                  </h6>
                  <h6 *ngIf="item?.name">
                    {{ item?.name || '' }}
                  </h6>
                  <p>{{ item?.module_event }}</p>
                </div>
                <div class="dt-info">
                  <div class="dt">
                    {{ item?.createdAt | date : 'dd MMM' }}
                  </div>
                  <div class="dt">
                    {{ item?.createdAt | date : 'h:mm a' }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div *ngIf="requestNotiList?.length == 0">
            No records found.
          </div>
        </div>
        <div
          class="view-all-list"
          *ngIf="totalInvCount >= 5 || totalNotiCount >= 5"
        >
          <a
            (click)="closeNotifyModal()"
            [routerLink]="['/home/all-notifications']"
            [queryParams]="{ type: currentTab }"
          >
            Go to Notifications
          </a>
        </div>
      </div>
    </div>
  </div>
</li>
