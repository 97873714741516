import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { TimePickerDirective } from './time-picker.directive';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, OverlayModule],
  declarations: [TimePickerComponent, TimePickerDirective],
  exports: [TimePickerComponent, TimePickerDirective],
})
export class TimePickerModule {}
