import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Provider,
  Renderer2,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TimePickerComponent } from './time-picker/time-picker.component';

const TIME_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerDirective),
  multi: true,
};

@Directive({
  selector: 'input[gfdEquipTimePicker]',
  providers: [TIME_PICKER_CONTROL_VALUE_ACCESSOR],
  exportAs: 'TimePickerRef',
})
export class TimePickerDirective
  implements ControlValueAccessor, OnDestroy, OnChanges
{
  @Input() minTime!: string;
  @Input() maxTime!: string;
  @Input() timeformat: 12 | 24 = 12;
  @Input() minuteInterval: 1 | 5 | 10 | 15 | 20 | 30 = 1;
  @Input() disable = false;
  @Output() timeChange: EventEmitter<string> = new EventEmitter();

  value!: string;
  elementRef: ElementRef<HTMLInputElement>;
  overlayRef!: OverlayRef | null;
  showing!: boolean;
  subcription!: Subscription;

  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.open();
  }

  @HostListener('focus')
  onElementFocus(): void {
    this.open();
  }

  @HostListener('blur')
  onElementBlur(): void {
    this.open();
  }
  constructor(
    elementRef: ElementRef,
    private overlay: Overlay,
    private renderer: Renderer2
  ) {
    this.elementRef = elementRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.minTime = changes['minTime']
      ? changes['minTime'].currentValue
      : this.minTime;
    this.maxTime = changes['maxTime']
      ? changes['maxTime'].currentValue
      : this.maxTime;
  }

  ngOnDestroy(): void {
    this.close();
  }

  writeValue(value: string): void {
    this.value = value;
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = (value: string) => {
      fn(value);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  /**
   * get overlay configuration
   * @returns
   */
  private getOverlayConfig(): OverlayConfig {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' }
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' }
      ),
    ];
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef.nativeElement)
      .withPush(false)
      .withPositions(positions);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'time-picker-panel-class',
    });
  }

  /**
   * open time picker component
   */
  open(): void {
    if (!this.overlayRef && !this.disable) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
      this.subcription = this.overlayRef
        .backdropClick()
        .subscribe(() => this.close());
      const componentPortal = new ComponentPortal(TimePickerComponent);
      const ref = this.overlayRef.attach(componentPortal);
      ref.instance.isInputFieldShow = false;
      ref.instance.minTime = this.minTime;
      ref.instance.maxTime = this.maxTime;
      ref.instance.timeformat = this.timeformat;
      ref.instance.minuteInterval = this.minuteInterval;
      ref.instance.writeValue(this.value);
      ref.instance.registerOnChange((value: string) => {
        this.value = value;
        this.onChange(value);
        this.timeChange.emit(value);
      });
      ref.instance.registerOnTouched(() => {
        this.onTouched();
      });

      this.showing = true;
    }
  }

  /**
   * close time picker component
   */
  close() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
      this.showing = false;
      this.subcription.unsubscribe();
    }
  }

  /**
   * set Min Time
   * @param minTime
   */
  setMinTime(min: string) {
    this.minTime = min;
  }

  /**
   * set Max Time
   * @param minTime
   */
  setMaxTime(max: string) {
    this.maxTime = max;
  }
}
