import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Provider,
  SimpleChanges,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TIME_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerComponent),
  multi: true,
};

@Component({
  selector: 'lib-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [TIME_PICKER_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  value!: string;
  @Input() isInputFieldShow = true;
  @Input() isOpen = false;
  @Input() timeformat: 12 | 24 = 12;
  @Input() minuteInterval: 1 | 5 | 10 | 15 | 20 | 30 = 1;
  @Input() identifier = 'time-picker';
  @Input() dataCy!: string;
  @Input() classes!: string | string[];
  @Input() timePickerContainerClass = '';
  @Input() minTime!: string;
  @Input() maxTime!: string;
  @Input() disable = false;
  @Input() placeholder!: string;

  onChanged!: (value: string) => void;
  onTouched!: () => void;
  periods: string[] = ['AM', 'PM'];
  hour = '';
  minute = '';
  period = '';
  disablePeriod!: string;
  disableHours: string[] = [];
  disableMinute: string[] = [];
  selectedValue!: string;

  /**
   * get hours array
   */
  get hours(): string[] {
    const hours = [];
    let start = 1;
    let end = 12;
    if (this.timeformat === 24) {
      start = 0;
      end = 23;
    }
    for (let i = start; i <= end; i++) {
      hours.push(i < 10 ? `0${i}` : `${i}`);
    }
    return hours;
  }

  /**
   * get minutes array
   */
  get minutes(): string[] {
    const minute = [];

    const end = 60 / this.minuteInterval;

    for (let i = 0; i <= end - 1; i++) {
      const curr = this.minuteInterval * i;

      minute.push(curr < 10 ? `0${curr}` : `${curr}`);
    }
    return minute;
  }

  get defaultTime(): string {
    return this.timeformat === 12 ? '01:00 AM' : '00:00';
  }

  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    this.scrollToActiveItem(
      '.time-picker-hour-group',
      '.time-picker-hour-item.active'
    );
    this.scrollToActiveItem(
      '.time-picker-minute-group',
      '.time-picker-minute-item.active'
    );
  }

  /**
   * get Time value as a  object of  hour minute and period
   * @param value
   * @returns
   */
  getTime(value: string): { hour: string; minute: string; period: string } {
    const arr = value ? value.replace(/:/g, ' ').split(' ') : [];
    return {
      hour: Number(arr[0]) < 10 ? `0${Number(arr[0])}` : `${arr[0]}`,
      minute: arr[1],
      period: arr[2],
    };
  }

  /**
   * set time value
   * @param value
   * @returns
   */
  setTime() {
    this.value =
      this.timeformat === 12
        ? `${this.hour}:${this.minute} ${this.period}`
        : `${this.hour}:${this.minute}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.minTime = changes['minTime']
      ? changes['minTime'].currentValue
      : this.minTime;
    this.maxTime = changes['maxTime']
      ? changes['maxTime'].currentValue
      : this.maxTime;

    this.changeValidation();
  }

  writeValue(value: string): void {
    this.value = value;
    this.selectedValue = value;
    if (!this.value) {
      this.value = this.getControlValue();
    }
    const selectedTime = this.getTime(this.value);
    this.hour = selectedTime.hour;
    this.minute = selectedTime.minute;
    this.period = selectedTime.period;

    this.onValueChange();
  }

  getControlValue(): string {
    return this.minTime || this.maxTime || this.defaultTime;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  /**
   * on control value change check value and apply validation
   */
  onValueChange(): void {
    this.changeValidation();
    if (this.disableHours.includes(this.hour)) {
      const min = this.getTime(this.minTime);
      const max = this.getTime(this.maxTime);
      this.hour = min.hour ?? max.hour;
      this.changeValidation();
      this.setTime();
    }
  }

  /**
   * set new  control value emit on change event
   */
  setValue(): void {
    if (this.hour && this.minute) {
      this.onTouched();
      this.value =
        this.timeformat === 12
          ? `${this.hour}:${this.minute} ${this.period}`
          : `${this.hour}:${this.minute}`;
      this.onChanged(this.value);
      this.selectedValue = this.value;
    }
  }

  /**
   *  on period change check validation and  update value
   * @param period
   */
  onPeriodChange(period: string): void {
    this.period = period;
    this.disableMinute = [];
    this.disableHours = [];
    if (this.minTime || this.maxTime) {
      this.changeValidation();
    }
    this.setValue();
  }

  /**
   *  on hour change check validation and  update value
   * @param hour
   */
  onHourChange(hour: string): void {
    this.hour = hour;
    this.disableMinute = [];
    if (this.minTime || this.maxTime) {
      this.changeValidation();
    }
    this.setValue();
  }

  /**
   * on minute value change update control value
   * @param minute
   */
  onMinuteChange(minute: string): void {
    this.minute = minute;
    this.setValue();
  }

  /**
   * apply validation for min and max time
   */
  changeValidation(): void {
    if (this.isAnyValid(this.minTime, this.maxTime)) {
      const min = this.getTime(this.minTime);
      const max = this.getTime(this.maxTime);
      if (this.timeformat === 12 && this.isAnyValid(min.period, max.period)) {
        this.setPeriodValidation(min.period, max.period);
      } else {
        this.setHourValidation(min?.hour, max?.hour, min?.minute, max?.minute);
      }
    }
  }

  /**
   * set validation for 12 hour format
   * @param minP
   * @param maxP
   */
  setPeriodValidation(minP: string, maxP: string): void {
    if (minP && maxP) {
      this.setMinAndMaxPeriodValidation();
    } else {
      this.setMinOrMaxPeriodValidation();
    }
  }

  /**
   * set min and max period validation
   */
  setMinAndMaxPeriodValidation(): void {
    const min = this.getTime(this.minTime);
    const max = this.getTime(this.maxTime);
    if (min?.period !== max?.period) {
      if (this.period === min?.period) {
        this.setHourValidation(min?.hour, '', min?.minute, '');
      }
      if (this.period === max?.period) {
        this.setHourValidation('', max?.hour, '', max?.minute);
      }
    } else {
      this.disablePeriod = this.periods.filter((p) => p !== min?.period)[0];
      this.period = min?.period;
      this.setHourValidation(min?.hour, max?.hour, min?.minute, max?.minute);
    }
  }

  /**
   * set min or max period validation
   */
  setMinOrMaxPeriodValidation(): void {
    const min = this.getTime(this.minTime);
    const max = this.getTime(this.maxTime);
    this.setDisablePriod(min.period, max.period);
    if (this.period === min?.period) {
      this.setHourValidation(min?.hour, '', min?.minute, '');
    } else if (this.period === max?.period) {
      this.setHourValidation('', max?.hour, '', max?.minute);
    } else {
      this.disableHours = [];
      this.disableMinute = [];
    }
  }

  setDisablePriod(minP: string, maxP: string) {
    if (minP === 'PM') {
      this.disablePeriod = 'AM';
      this.period = 'PM';
    } else if (maxP === 'AM') {
      this.disablePeriod = 'PM';
      this.period = 'AM';
    }
  }

  /**
   * set hour validation
   */
  setHourValidation(
    minH: string,
    maxH: string,
    minM: string,
    maxM: string
  ): void {
    this.setDisableHours(minH, maxH);
    if (this.disableHours.includes(this.hour)) {
      this.hour = minH ?? maxH;
      this.setTime();
    }
    this.disableMinute = [];
    this.checkHour(minH, maxH, minM, maxM);
  }

  /**
   * set disable hours
   * @param minH
   * @param maxH
   */
  setDisableHours(minH: string, maxH: string): void {
    if (minH && maxH) {
      this.disableHours = this.hours.filter(
        (h, index) =>
          index < this.hours.indexOf(minH) || index > this.hours.indexOf(maxH)
      );
    } else {
      this.disableHours = this.hours.filter((h, index) =>
        minH
          ? index < this.hours.indexOf(minH)
          : index > this.hours.indexOf(maxH)
      );
    }
  }

  /**
   * check hour and update minute validation
   */
  checkHour(minH: string, maxH: string, minM: string, maxM: string): void {
    if (this.isEqual(minH, maxH, this.hour)) {
      this.setMinuteValidation(minM, maxM);
    } else if (this.isEqual(this.hour, minH)) {
      this.setMinuteValidation(minM, '');
    } else if (this.isEqual(this.hour, maxH)) {
      this.setMinuteValidation('', maxM);
    }
  }

  /**
   * check strings are equal or not
   * @param key1
   * @param key2
   * @returns
   */
  isEqual(key1: string, key2: string, key3?: string): boolean {
    return key3 ? key1 === key2 && key1 === key3 : key1 === key2;
  }

  /**
   * return if any one  key is truthy
   * @param key1
   * @param key2
   * @returns
   */
  isAnyValid(key1: string, key2: string): boolean {
    return key1 || key2 ? true : false;
  }

  /**
   * set minute validation
   */
  setMinuteValidation(minM: string, maxM: string): void {
    this.setDisableMinutes(minM, maxM);
    if (this.disableMinute.includes(this.minute)) {
      this.minute = minM ?? maxM;
      this.setTime();
    }
  }

  /**
   * set disable minutes
   * @param minM
   * @param maxM
   */
  setDisableMinutes(minM: string, maxM: string): void {
    if (minM && maxM) {
      this.disableMinute = this.minutes.filter(
        (m) => Number(m) < Number(minM) && Number(m) > Number(maxM)
      );
    } else {
      this.disableMinute = this.minutes.filter((m) =>
        minM ? Number(m) < Number(minM) : Number(m) > Number(maxM)
      );
    }
  }

  private scrollToActiveItem(groupSelector: string, itemSelector: string) {
    const group = this.el.nativeElement.querySelector(groupSelector);
    const activeItem = this.el.nativeElement.querySelector(itemSelector);
    if (group && activeItem) {
      group.scrollTop = activeItem.offsetTop - 45;
    }
  }
}
