import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAllowDecimal]',
})
export class AllowDecimalDirective {
  @Input() allowDecimals = true;
  @Input() allowSign = false;
  @Input() decimalLength!: number;
  @Input() maxAllowedValue!: number;
  @Input() minAllowedValue!: number;
  // eslint-disable-next-line @typescript-eslint/quotes
  @Input() decimalSeparator: "'" | '.' | ',' = '.';
  @Output() valuePasted: EventEmitter<any> = new EventEmitter<any>();

  previousValue = '';

  /**
   * Class constructor
   *
   * @param hostElement
   */
  constructor(@Self() @Optional() public controlDir: NgControl) {}

  get regex() {
    if (!this.allowDecimals && !this.allowSign) {
      return '^[0-9]*$';
    }
    if (!this.allowDecimals && this.allowSign) {
      return '^-?[0-9]+$';
    }
    if (this.allowDecimals && !this.allowSign) {
      return `^[0-9]+(\\${this.decimalSeparator}[0-9]{0,${this.decimalLength}}){0,1}$`;
    }
    if (this.allowDecimals && this.allowSign) {
      return `^-?[0-9]+(\\${this.decimalSeparator}[0-9]{0,${this.decimalLength}}){0,1}$`;
    } else {
      return '';
    }
  }

  /**
   * Event handler for host's change event
   *
   * @param e
   */
  @HostListener('change', ['$event']) onChange(e: any) {
    if (this.validateValue(e.target.value)) {
      return;
    }
  }

  /**
   * Event handler for host's paste event
   *
   * @param e
   */
  @HostListener('paste', ['$event']) onPaste(e: any) {
    // get and validate data from clipboard
    const pastedText = e.clipboardData.getData('text/plain');

    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    const currentValue = e.target.value;
    e.preventDefault();
    if (this.validateValue(pastedText)) {
      const maxLength = parseInt(e.target.getAttribute('maxlength'), 10);
      const currentLength = currentValue.length;
      const remainingLength = maxLength - currentLength;
      const selectedTextLength = selectionEnd - selectionStart;
      const pasteLength =
        pastedText.length > remainingLength + selectedTextLength
          ? remainingLength + selectedTextLength
          : pastedText.length;
      //set pasted value to form control
      e.target.value =
        currentValue.slice(0, selectionStart) +
        pastedText.slice(0, pasteLength) +
        currentValue.slice(selectionEnd);

      //set cursor at the end of pasted text
      e.target.setSelectionRange(
        selectionStart + pasteLength,
        selectionStart + pasteLength
      );
      this.valuePasted.emit(e.target.value);
      if (this.controlDir && this.controlDir.control) {
        this.controlDir.control.setValue(e.target.value);
      }
    }
  }

  /**
   * Event handler for host's keydown event
   *
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: any) {
    const cursorPosition: number = e.target.selectionStart;
    const originalValue: string = e.target.value;
    const key: string = this.getName(e);
    const isControlOrCommand = e.ctrlKey === true || e.metaKey === true;
    const isSignExists = originalValue.includes('-');
    const isSeparatorExists = originalValue.includes(this.decimalSeparator);

    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Tab',
      'Enter',
    ];

    if (
      this.maxAllowedValue &&
      key === 'Backspace' &&
      originalValue.charAt(cursorPosition - 1) === '.' &&
      +(
        originalValue.substring(0, cursorPosition - 1) +
        originalValue.substring(cursorPosition)
      ) > this.maxAllowedValue
    ) {
      e.preventDefault();
      return;
    }

    const isSeparatorIsCloseToSign = isSignExists && cursorPosition <= 1;
    if (this.allowDecimals && !isSeparatorIsCloseToSign && !isSeparatorExists) {
      if (!this.decimalSeparator) {
        allowedKeys.push('.');
      } else {
        allowedKeys.push(this.decimalSeparator);
      }
    }

    const isFirstCharacterIsSeparator =
      originalValue?.charAt(0) !== this.decimalSeparator;
    if (
      this.allowSign &&
      !isSignExists &&
      isFirstCharacterIsSeparator &&
      cursorPosition === 0
    ) {
      allowedKeys.push('-');
    }

    if (
      allowedKeys.indexOf(key) !== -1 ||
      (key === 'a' && isControlOrCommand) ||
      (key === 'c' && isControlOrCommand) ||
      (key === 'v' && isControlOrCommand) ||
      (key === 'x' && isControlOrCommand)
    ) {
      return;
    }

    this.previousValue = originalValue;

    let updateVal = e.target.value || '';
    if (updateVal) {
      updateVal =
        updateVal.substring(0, cursorPosition) +
        key +
        updateVal.substring(cursorPosition);
    } else {
      updateVal += key;
    }
    const isNumber =
      new RegExp('^[0-9]*$').test(key) &&
      new RegExp(this.regex).test(updateVal);

    if (
      isNumber &&
      (!+this.minAllowedValue || +updateVal >= +this.minAllowedValue) &&
      (!(+this.maxAllowedValue >= 0) || +updateVal <= +this.maxAllowedValue)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }

  ngOnInit() {
    if (!(this.decimalLength >= 0)) {
      this.decimalLength = 2;
    }
  }

  /**
   * Test whether value is a valid number or not
   *
   * @param value
   */
  validateValue(value: string) {
    // choose the appropiate regular expression

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value?.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value?.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      value = value + 0;
    }

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const isValid: boolean = value ? new RegExp(this.regex).test(value) : true;
    return isValid;
  }

  /**
   * Get key's name
   *
   * @param e
   */
  getName(e: any) {
    if (e.key) {
      return e.key;
    } else {
      if (e.keyCode && String.fromCharCode) {
        this.getCode(e.keyCode);
      }
    }
  }

  getCode(value) {
    switch (value) {
      case 8:
        return 'Backspace';
      case 9:
        return 'Tab';
      case 27:
        return 'Escape';
      case 37:
        return 'ArrowLeft';
      case 39:
        return 'ArrowRight';
      case 190:
        return '.';
      case 109:
        return '-'; // minus in numbpad
      case 173:
        return '-'; // minus in alphabet keyboard in firefox
      case 189:
        return '-'; // minus in alphabet keyboard in chrome
      default:
        return String.fromCharCode(value);
    }
  }
}
