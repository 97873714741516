import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';

import { NgIdleModule } from '@ng-idle/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { SafePipeModule } from 'safe-pipe';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { AppSettingsInitializerFactory } from './core/initializer/app-settings.initializer';
import { UserIdentityInitializerFactory } from './core/initializer/user-identity.initializer';
import { HTTP_INTERCEPTOR_PROVIDERS } from './core/interceptors';
import { AccountSettingsService, StorageService } from './core/services';
import { CredentialService } from './core/services/credential.service';
import { GeneralService } from './core/services/general.service';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { SharedModule } from './shared/shared.module';
import { AppSettingsService } from './core/services/app-setting.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    SafePipeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      includeTitleDuplicates: true,
    }),
    NgIdleModule.forRoot(),
  ],
  providers: [
    GeneralService,
    HTTP_INTERCEPTOR_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppSettingsInitializerFactory,
      deps: [HttpClient, StorageService, AppSettingsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: UserIdentityInitializerFactory,
      deps: [StorageService, CredentialService, AccountSettingsService, GeneralService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
