<ng-container *ngIf="isInputFieldShow">
  <div
    class="time-picker-input-container d-flex align-items-center border-0 border-bottom border-gray-light"
    #Timeinput
    cdkOverlayOrigin
    #originOverlay="cdkOverlayOrigin"
  >
    <input
      type="text"
      [ngClass]="classes"
      [attr.data-cy]="dataCy"
      [(ngModel)]="selectedValue"
      [name]="identifier"
      [id]="identifier"
      readonly
      (focus)="isOpen = true"
      [disabled]="disable"
      [placeholder]="placeholder"
    />
    <button
      type="button"
      [ngClass]="{ disabled: disable }"
      (click)="isOpen = true"
      [id]="identifier + 'Icon'"
      [attr.data-cy]="dataCy + '-icon'"
      class="btn border-0 p-0"
    >
      <span class="material-icons time-picker-icon fs-16 ms-4"
        >watch_later</span
      >
    </button>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="originOverlay"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="isOpen = false"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop-cs'"
    [cdkConnectedOverlayPanelClass]="'time-picker-panel-class'"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </ng-template>
</ng-container>

<ng-container
  *ngIf="!isInputFieldShow"
  [ngTemplateOutlet]="content"
></ng-container>

<ng-template #content>
  <div class="time-picker-container" [ngClass]="timePickerContainerClass">
    <div class="time-picker-heading bg-header-primary text-white">
      {{ value }}
    </div>
    <div class="time-picker-content">
      <div class="time-picker-hour-group" *ngIf="timeformat === 12">
        <button
          type="button"
          class="time-picker-hour-item bg-header-primary text-white"
          *ngFor="let hourItem of hours.slice(0, 12)"
          [class.active]="hourItem === hour"
          [ngClass]="{
            'bg-header-primary text-white': hourItem === hour
          }"
          [disabled]="disableHours.includes(hourItem)"
          (click)="onHourChange(hourItem)"
        >
          {{ hourItem }}
        </button>
      </div>
      <div class="time-picker-hour-group" *ngIf="timeformat === 24">
        <button
          type="button"
          class="time-picker-hour-item"
          *ngFor="let hourItem of hours"
          [ngClass]="{
            active: hourItem === hour
          }"
          [disabled]="disableHours.includes(hourItem)"
          (click)="onHourChange(hourItem)"
        >
          {{ hourItem }}
        </button>
      </div>
      <div class="time-picker-minute-group">
        <button
          type="button"
          class="time-picker-minute-item"
          *ngFor="let minuteItem of minutes"
          [class.active]="minuteItem === minute"
          [ngClass]="{
            'bg-header-primary text-white': minuteItem === minute
          }"
          [disabled]="disableMinute.includes(minuteItem)"
          (click)="onMinuteChange(minuteItem)"
        >
          {{ minuteItem }}
        </button>
      </div>
      <div class="time-picker-period-group" *ngIf="timeformat === 12">
        <button
          type="button"
          class="time-picker-period-item"
          *ngFor="let periodItem of periods"
          [class.active]="periodItem === period"
          [ngClass]="{
            'bg-header-primary text-white': periodItem === period
          }"
          [disabled]="disablePeriod && disablePeriod === periodItem"
          (click)="onPeriodChange(periodItem)"
        >
          {{ periodItem }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
