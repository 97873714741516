import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AccountSettingsService,
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-new-email-address-modal',
  templateUrl: './new-email-address-modal.component.html',
  styleUrls: ['./new-email-address-modal.component.scss'],
})
export class NewEmailAddressModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output() closeNewEmailModal = new EventEmitter();
  @Output() newEmailID = new EventEmitter();

  showPwd = false;

  emailForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      ValidationService.required,
      Validators.email,
      Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'),
    ]),
    password: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    private accountSettingService: AccountSettingsService,
    private router: Router,
    private generalService: GeneralService,
    private storageServie: StorageService
  ) {
    super();
  }

  ngOnInit(): void {}

  get f() {
    return this.emailForm.controls;
  }

  sendOTP(): void {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      return;
    } else {
      const { email, password } = this.emailForm.getRawValue();
      const seller_id = this.storageServie.getCookie('userID');

      this.accountSettingService
        .changeEmail({ email: email, password, id: seller_id })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response) => {
            if (response?.success) {
              this.generalService.displaySuccess(response?.message);
              this.closeModal(email);
            } else {
              this.generalService.displayError(response?.message);
            }
          },
          error: (error) => {
            this.generalService.displayError(error?.error?.message);
          },
        });
    }
  }

  closeModal(email?: string): void {
    this.closeMdl?.nativeElement?.click();
    this.newEmailID?.emit(email);
    this.closeNewEmailModal?.emit(true);
  }

  resetForm() {
    this.emailForm.reset();
  }
}
