import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AccountSettingsService,
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-email-configuration-modal',
  templateUrl: './email-configuration-modal.component.html',
})
export class EmailConfigurationModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @ViewChild('closeMdl') closeMdl: any;
  @Output() closeEmailModal = new EventEmitter();

  passStrength: any;
  isPwdValid = false;
  showPwd: any = false;
  showRetypePwd: any = false;
  passMatch: any = false;

  emailForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      ValidationService.required,
      Validators.pattern(
        /^([A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))$/
      ),
    ]),
    password: new UntypedFormControl('', [ValidationService.required]),
    rePassword: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    private accountSettingService: AccountSettingsService,
    private generalService: GeneralService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit(): void {}

  save(): void {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      return;
    } else {
      if (
        this.emailForm.value.password === this.emailForm.value.rePassword &&
        this.isPwdValid
      ) {
        const payload = {
          email: this.emailForm.value.email,
          password: this.emailForm.value.password,
          seller_id: this.storageService.getCookie('userID'),
        };
        this.accountSettingService
          .editEmail(payload)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (res) => {
              if (res) {
                this.generalService.displaySuccess(res?.message);
                this.closeEmailModal.emit();
                this.closeModal();
                this.reset();
              }
            },
            (error) => {
              this.generalService.displayError(error?.error?.message);
            }
          );
      } else {
        if (this.emailForm.value.password !== this.emailForm.value.rePassword) {
          this.generalService.displayError('Password Mismatched');
        } else {
          this.generalService.displayWarning('Please enter vaild password.');
        }
      }
    }
  }

  comparePassword() {
    const { password, rePassword } = this.emailForm.getRawValue();
    if (password !== rePassword) this.passMatch = false;
    else this.passMatch = true;
  }

  validatePwd(): void {
    const { password } = this.emailForm.getRawValue();
    this.passStrength = this.generalService.validatePassword(password);
    const { isContainSpace, contains } = this.passStrength?.passStrength;
    if (
      !isContainSpace &&
      contains?.includes('lowercase') &&
      contains?.includes('number') &&
      contains?.includes('uppercase')
    ) {
      this.isPwdValid = true;
    } else {
      this.isPwdValid = false;
    }
  }

  reset(): void {
    this.emailForm.reset();
  }

  get f() {
    return this.emailForm.controls;
  }

  closeModal(): void {
    this.closeMdl.nativeElement.click();
  }
}
