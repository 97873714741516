import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  GeneralService,
  StorageService,
  ValidationService,
} from '@app/core/services';
import { SupportService } from '@app/core/services/support.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-new-ticket-modal',
  templateUrl: './add-new-ticket-modal.component.html',
  styleUrls: ['./add-new-ticket-modal.component.scss'],
})
export class AddNewTicketModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  config!: AngularEditorConfig;
  @ViewChild('closeMdl') closeMdl: any;
  @Input() couponID: any = 0;
  @Output() modalClose = new EventEmitter();

  allOrderList: Array<Select2OptionData> = [];
  allItemList: any = [];
  itemObj = {};
  items: any = [];
  ticketData = new UntypedFormGroup({
    subject: new UntypedFormControl('', [ValidationService.required]),
    description: new UntypedFormControl('', [ValidationService.required]),
    order: new UntypedFormControl(''),
    item: new UntypedFormControl(''),
  });

  constructor(
    private generalService: GeneralService,
    private supportService: SupportService,
    private storageService: StorageService
  ) {
    super();
  }

  get f() {
    return this.ticketData.controls;
  }

  ngOnInit(): void {
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
    };
    this.getOrderReferenceList();
  }

  getOrderReferenceList(): void {
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
    };
    this.supportService
      .getOrderReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.allOrderList = response?.data;
        }
      });
  }

  getItemReferenceList(orderID: any): void {
    this.allItemList = [];
    const payload = {
      seller_id: this.storageService.getCookie('userID'),
      order_id: orderID ? orderID : '',
    };
    this.supportService
      .getOrderWiseItemReferenceList(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response && response.data) {
          this.items = response?.data;
          if (this.items?.length > 0) {
            this.items?.map((item: any) => {
              item.text = item.variants?.name
                ? item.name + ' (' + item.variants?.name + ')'
                : item?.name;
              this.allItemList?.push({
                text: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
                id: item.variants?.name
                  ? item.name + ' (' + item.variants?.name + ')'
                  : item?.name,
              });
            });
          }
        }
      });
  }

  getSelectedItem(data: any): void {
    this.itemObj = this.items?.filter((item: any) => {
      if (item.text == data) {
        return item;
      }
    })?.[0];
  }

  save(): void {
    if (this.ticketData.invalid) {
      this.ticketData.markAllAsTouched();
    } else {
      const payload = {
        subject: this.ticketData.value?.subject,
        description: this.ticketData.value?.description,
        order: this.ticketData.value?.order,
        seller: this.storageService.getCookie('userID'),
        item: this.itemObj ? this.itemObj : {},
        status: 0, // 0 => open, 1=> close
        tickets_by: 1, //0 => customer to seller, 1=> seller to admin
      };
      this.supportService.addTicket(payload).subscribe(
        () => {
          this.modalClose.emit({ onSaveClose: true });
          this.closeMdl.nativeElement.click();
        },
        (err) => {
          this.generalService.displayError(err.error.message);
        }
      );
    }
  }

  close(): void {
    this.ticketData.reset();
    this.modalClose.emit();
  }
}
